.HeaderWrap .TopNavBar .ToplinksGroup {
    .LogoWrap {
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
            padding-left: calc(var(--spacing-unit) * 3.5);
            width: calc(100% - 180px);
        }

        @media (min-width: 768px) {
            height: 71px;
            padding: 0 calc(var(--spacing-unit) * 1.25) 0 calc(var(--spacing-unit) * 5);
        }

        a {
            display: flex;
            max-width: 240px;
            max-height: 36px;

            @media (max-width: 1021px) {
                max-width: 133px;
                max-height: 20px;
            }

            svg {
                height: 100%;
                width: auto;
            }
        }
    }
}
