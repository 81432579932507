.error-main {
    h1 {
        font-size: 2.4em;
        text-transform: uppercase;
        font-weight: var(--font-weight-bold);
        margin-bottom: 20px;
    }
    h2 {
        font-size: 1.5em;
    }
    p,
    .error-list-options {
        font-size: 1.5em;
        padding-bottom: 10px;
    }
    .error-list-options {
        padding-left: 20px;

        li {
            padding-bottom: 5px;
            list-style: disc;
        }
        a {
            color: var(--site-button-primary-color);
            font-weight: var(--font-weight-bold);

            &:hover {
                color: var(--site-button-primary-background-hover);
            }
        }
    }
    .error-nav {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 30px;
        max-width: 700px;

        a {
            background-color: var(--site-button-primary-background);
            color: var(--site-button-primary-color);
            border-radius: 4px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            padding: calc(2.5 * var(--spacing-unit));
            letter-spacing: 0.03em;
            font-weight: var(--font-weight-bold);

            span {
                display: inline-flex;
                font-size: 1rem;
                text-transform: uppercase;
                color: var(--site-black);
                letter-spacing: 0.03em;
                font-family: var(--default-font-family);
            }

            &:hover {
                background-color: var(--site-button-primary-background-hover);
            }
        }
    }

    @media screen and (min-width: 768px) {
        margin: 50px auto;
        padding: 0 calc(7.5 * var(--spacing-unit));

        .error-nav a {
            width: calc(50% - 5px);
        }
    }
}

footer.error-footer {
    margin: 30px auto 0;
}

.socialLinksWrapper {
    max-width: 1200px;
    border-bottom: 0;

    ul {
        display: flex;
    }

    li {
        display: flex;
        width: 25%;
        text-align: center;
        justify-content: center;

        a {
            background-image: var(--spritesheet);
            text-indent: -9999px;
            background-repeat: no-repeat;
            background-size: 750px 750px;
            width: 50px;
            height: 50px;
            max-width: 50px;
        }
    }

    li.socFacebook a {
        background-position: -11px -300px;
    }

    li.socInstagram a {
        background-position: -12px -425px;
    }

    li.socTwitter a {
        background-position: -75px -300px;
    }

    li.socTikTok a {
        background-position: -75px -425px;
    }

    li.socSpotify a {
        background-position: -90px -673px;
    }

    @media screen and (min-width: 768px) {
        width: 25%;

        li {
            justify-content: flex-start;
        }
    }
}
