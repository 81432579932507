/* New footer */
.FooterGroupLink {
    width: 100%;
    float: none;
    padding: 0;

    li {
        line-height: 1.6em;
        padding: 0;

        a {
            padding: 5px 10px;
            font-size: var(--body-font-size);
            color: var(--site-primary);
            line-height: 2;
            font-weight: var(--font-weight-regular);
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

h2.FooterHeader {
    font-size: var(--subtitle-font-size);
}

.FooterMenuWrap {
    float: none;
}

.FooterGroup,
.dbc,
.FooterPane,
.FooterPane2 {
    margin: 0 auto;
    max-width: 1440px;
    display: block;
    float: none;
    position: relative;
    padding: 0 10px;
}

.FooterGroup {
    padding: 0;
    position: relative;
    overflow: visible;

    &::before,
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        bottom: -1px;
    }

    &::before {
        right: 100%;
    }

    &::after {
        left: 100%;
    }
}

.FooterWrap {
    border-top: none;
    z-index: 10 !important;
    overflow: hidden;
    background-color: var(--site-lightest-grey);
    padding: 0;
    margin-top: calc(var(--spacing-unit) * 5);

    .FooterTop {
        display: table;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    .footerContainer {
        border-top: 1px solid var(--site-mid-grey);
    }

    .FooterTop .SubTitle {
        display: block;
        color: var(--site-primary);
        font-size: var(--subtitle-font-size);
    }

    .FooterTop span.hidden-xs {
        display: block;
        color: var(--site-white);
        margin-top: 5px;
        font-size: var(--subtitle-font-size);
    }

    .FooterTop span.hidden-xs span {
        text-decoration: underline;
    }
}

.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 40px;
    background-size: 934px 950px;
}

.FooterWrap .footerContainer .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsStores a::before,
.footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    margin-right: 10px;
}

.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before {
    background-position: -175px -448px;
}

.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before {
    background-position: -24px -448px;
}

#topLinkMenu ul.TopLinkMenu .iconsCS a:hover::before {
    background-position: -24px -507px;
}

.FooterSubGroup .FooterHeader {
    margin-bottom: 0;
    font-size: var(--subtitle-font-size);
    font-weight: var(--font-weight-bold);
    color: var(--site-primary);
    border: none;
    float: none;
}

.FooterGroup4 {
    .FooterGroupLink a::before {
        content: "";
        display: block;
        position: absolute;
        top: 7px;
        left: 10px;
        width: 20px;
        height: 20px;
    }

    .FooterGroupLink li a {
        padding-left: 38px;
        position: relative;
    }

    .FooterGroupLink li.facebookFooter a::before {
        content: url('data:image/svg+xml;utf8,<svg width="20px" height="20px" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="m11.5,19.5h-4.167v-9.5h-2.083v-3.273h2.083v-1.996c0-2.634,1.167-4.231,4.583-4.231h2.833v3.273h-1.75c-1.333,0-1.417.479-1.417,1.277v1.597h3.167l-.333,3.273h-2.833l-.083,9.58Z"></path></svg>');
        left: 8px;
    }

    .FooterGroupLink li.instaFooter a::before {
        content: url('data:image/svg+xml;utf8,<svg width="20px" height="20px" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path class="cls-1" d="m18.821,6.469c0-.7-.2-1.5-.4-2.2-.2-.6-.6-1.1-1-1.6s-1-.8-1.6-1c-.7-.3-1.4-.4-2.2-.4-1,0-1.3-.1-3.7-.1-1.2,0-2.5,0-3.7.1-.7,0-1.5.2-2.2.4-.6.2-1.1.6-1.6,1s-.8,1-1,1.6c-.3.7-.4,1.4-.4,2.2,0,1-.1,1.3-.1,3.7,0,1.2,0,2.5.1,3.7,0,.7.2,1.5.4,2.2.2.6.6,1.1,1,1.6s1,.8,1.6,1c.7.3,1.4.4,2.2.4,1,0,1.3.1,3.7.1,1.2,0,2.5,0,3.7-.1.7,0,1.5-.2,2.2-.4,1.2-.5,2.1-1.4,2.6-2.6.3-.7.4-1.4.4-2.2,0-1,.1-1.3.1-3.7-.1-2.5-.1-2.8-.1-3.7Zm-1.7,7.3c0,.6-.1,1.2-.3,1.7-.3.8-.9,1.4-1.7,1.7-.5.2-1.1.3-1.7.3-.9,0-1.2.1-3.6.1-1.2,0-2.4,0-3.6-.1-.6,0-1.2-.1-1.7-.3-.4-.1-.7-.4-1-.7-.3-.3-.5-.6-.7-1-.2-.5-.3-1.1-.3-1.7,0-.9-.1-1.2-.1-3.6,0-1.2,0-2.4.1-3.6,0-.6.1-1.2.3-1.7.1-.4.4-.7.7-1s.6-.5,1-.7c.5-.2,1.1-.3,1.7-.3h3.6c1.2,0,2.4,0,3.6.1.6,0,1.2.1,1.7.3.4.1.7.4,1,.7.3.3.5.6.7,1,.2.5.3,1.1.3,1.7,0,.9.1,1.2.1,3.6,0,1.2,0,2.3-.1,3.5Z"/><path class="cls-1" d="m14.621,4.269c-.6,0-1.1.5-1.1,1.1s.5,1.1,1.1,1.1,1.1-.5,1.1-1.1-.4-1.1-1.1-1.1c.1,0,0,0,0,0Z"/><path class="cls-1" d="m9.821,5.569c-2.5,0-4.6,2-4.6,4.6h0c0,2.5,2.1,4.6,4.6,4.6s4.6-2,4.6-4.6h0c0-2.5-2-4.6-4.6-4.6h0Zm3,4.6c0,1.7-1.3,3-3,3s-3-1.3-3-3,1.3-3,3-3c1.6,0,3,1.3,3,3h0Z"/><path class="cls-1" d="m14.621,4.269c-.6,0-1.1.5-1.1,1.1s.5,1.1,1.1,1.1,1.1-.5,1.1-1.1-.4-1.1-1.1-1.1c.1,0,0,0,0,0Z"></path><path class="cls-1" d="m9.821,5.569c-2.5,0-4.6,2-4.6,4.6h0c0,2.5,2.1,4.6,4.6,4.6s4.6-2,4.6-4.6h0c0-2.5-2-4.6-4.6-4.6h0Zm3,4.6c0,1.7-1.3,3-3,3s-3-1.3-3-3,1.3-3,3-3c1.6,0,3,1.3,3,3h0Z"></path></svg>');
    }

    .FooterGroupLink li.twitterFooter a::before {
        content: url('data:image/svg+xml;utf8,<svg width="15px" height="15px" viewBox="0 0 15 15" fill="black" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M 8.746094 6.222656 L 14.222656 0 L 12.925781 0 L 8.171875 5.402344 L 4.378906 0 L 0 0 L 5.738281 8.167969 L 0 14.691406 L 1.296875 14.691406 L 6.3125 8.988281 L 10.324219 14.691406 L 14.699219 14.691406 Z M 6.972656 8.242188 L 6.390625 7.425781 L 1.761719 0.953125 L 3.757812 0.953125 L 7.488281 6.175781 L 8.070312 6.992188 L 12.925781 13.78125 L 10.933594 13.78125 Z M 6.972656 8.242188 "></path></svg>');
        padding-left: 3px;
    }

    .FooterGroupLink li.tiktokFooter a::before {
        content: url('data:image/svg+xml;utf8,<svg width="20px" height="20px" viewBox="0 0 20 20" fill="black" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path xmlns="http://www.w3.org/2000/svg" class="cls-1" d="m13.696.542c.312,2.684,1.81,4.284,4.416,4.455v3.019c-1.561.082-3.103-.368-4.374-1.277v5.646c0,7.173-7.82,9.414-10.964,4.274-2.017-3.309-.779-9.115,5.702-9.347v3.183c-.511.081-1.014.205-1.504.369-.332.064-.647.193-.929.379-.282.186-.524.426-.713.706-.189.28-.32.594-.387.926-.067.331-.068.672-.003,1.003.437,3.087,6.1,4.001,5.63-2.031V.542h3.127Z"></path></svg>');
    }
}

.signup-wrapper .signupInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 700px;
    float: none;
    border: none;
    margin: calc(var(--spacing-unit) * 2.5) auto 0;
    padding: calc(var(--spacing-unit) * 5) 0;
}

.signup-wrapper .signupInner .signupInputBox {
    display: flex;
    flex-direction: column;
    border-radius: var(--spacing-unit);
    margin: 0;
    overflow: hidden;
    width: 100%;
    margin-bottom: calc(var(--spacing-unit) * 2.5);
}

.signup-wrapper input#signupEmail {
    padding: 5px 15px;
    font-size: var(--body-font-size);
    opacity: 0.9;
    text-align: center;
    height: 42px;
    border: 1px solid var(--site-dark-grey);
    border-radius: var(--site-border-radius);
    transition: border-color 0.3s linear;
}

@media (min-width: 768px) {
    .signup-wrapper input#signupEmail {
        text-align: left;
    }
}

.signup-wrapper input#signupEmail:focus {
    border-color: var(--site-primary);
}

.signup-wrapper input.signbut {
    font-size: var(--body-font-size);
    font-weight: var(--font-weight-bold);
    height: 42px;
    padding: 0 calc(var(--spacing-unit) * 5);
    border-radius: var(--site-border-radius);
    background-color: var(--site-button-primary-background);
    color: var(--site-button-primary-color);
}

.signup-wrapper input.signbut:hover {
    background-color: var(--site-button-primary-background-hover) !important;
    color: var(--site-button-primary-color-hover);
}

.signup-wrapper .signupInner .join-us-text {
    text-align: left;
}

.signup-wrapper .signupInner .SubTitle {
    color: var(--site-primary);
    font-size: var(--subtitle-font-size);
    margin-bottom: calc(var(--spacing-unit) * 4.5);
    text-transform: uppercase;
}

.signup-wrapper .signupInner .sub-buttons {
    padding: 0;
}

.signup-wrapper .signupInner .NewsletterPadding {
    padding-bottom: calc(var(--spacing-unit) * 2);
}

.signup-wrapper .signupInner .disclaimerText {
    display: none;
}

@media (min-width: 768px) {
    .signup-wrapper .signupInner .join-us-text {
        padding-top: 0;
        overflow: hidden;
    }

    .signup-wrapper .signupInner .signupInputBox {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 0;
    }

    .signup-wrapper .signupInner .NewsletterPadding,
    #signupEmail {
        width: 500px;
        padding-right: calc(var(--spacing-unit) * 2);
    }

    .signup-wrapper .signupInner .newsLetterButton,
    #enterNewsLetter {
        width: 200px;
    }

    .signup-wrapper .signupInner .disclaimerText {
        display: block;
        margin-top: calc(var(--spacing-unit) * 2.5);
        color: var(--site-primary);
    }

    .signup-wrapper .signupInner .disclaimerText a {
        color: var(--site-primary);
    }

    .signup-wrapper .signupInner .disclaimerText p {
        color: var(--site-primary);
        font-size: var(--body-font-size);
        text-align: center;
    }
}

#topLinkMenu ul.TopLinkMenu .iconsStores a {
    padding-top: calc(var(--spacing-unit) * 5);
    padding-bottom: calc(var(--spacing-unit) * 5);
}

.footerLogo {
    text-align: center;
    padding-top: calc(var(--spacing-unit) * 7);
}

.FooterSearchCountry .FooterHeader h2 {
    color: var(--site-mid-grey);
    font-size: 1.4em;
    border-bottom: 1px solid var(--site-light-grey);
    font-weight: bold;
}

.u-vh {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.footerInfoSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid var(--site-mid-grey);

    .global-icon-account svg path {
        stroke: var(--site-primary);
        stroke-width: 2px;
    }
}

#footApp,
#footAppGooglePlay {
    width: 50%;
    float: left;
    text-align: center;
}

#footApp a:hover > .ico {
    background-position: -662px -275px;
    width: 120px;
}

#footApp .ico {
    background-position: -662px -237px;
    width: 120px;
}

#footAppGooglePlay a:hover > .ico {
    background-position: -605px -1214px;
    width: 120px;
}

#footAppGooglePlay .ico {
    background-position: -605px -1174px;
    width: 120px;
}

.footerMenu .FooterSubGroup {
    background-color: transparent;
    min-height: 0;
    margin: 0;
    height: auto;
}

.footerPane .FooterHeader {
    padding: calc(var(--spacing-unit) * 2.5) 0;
    display: block;
    margin-bottom: calc(var(--spacing-unit) * 1.5);
    width: 100%;
}

.FooterPane2 {
    margin: 0 auto;
    max-width: 1400px;
}

.Copyright {
    max-width: 1440px;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: calc(var(--spacing-unit) * 3.75) calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 5);
    }

    .footerCopyLine {
        width: 100%;
        padding-bottom: calc(var(--spacing-unit) * 5);

        @media (min-width: 768px) {
            padding-bottom: 0;
        }
    }

    .footerCopyLine a {
        display: none;
        color: var(--site-dark-grey);
        font-size: 12px;
        text-decoration: underline;
        padding: 0 calc(var(--spacing-unit) * 3);

        @media (min-width: 768px) {
            display: inline-block;
        }
    }

    .footerCopyLine span {
        color: var(--site-dark-grey);
    }

    .footerPaymentLineBorder {
        padding: calc(var(--spacing-unit) * 4);
        width: 100%;

        @media (min-width: 768px) {
            padding: 0;
            text-align: right;
            width: auto;
            flex: 1 1 auto;
            max-width: 40%;
        }

        .payment-options {
            padding: 0;
            margin: 0;
        }
    }

    span {
        color: var(--site-dark-grey);
        font-size: 12px;
        margin: 0;
    }
}

.FooterWrap .footerContainer span.hidden-xs {
    display: block;
    color: var(--site-white);
    margin-top: var(--spacing-unit);
    font-size: 1.2em;
}

.FooterWrap .footerContainer span.hidden-xs span {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .footerelements {
        padding: 0;
    }

    .FooterSubGroup .FooterHeader {
        padding: calc(var(--spacing-unit) * 4) 0;
        text-align: left;
        width: 100%;
        float: none;
        position: relative;
        display: inline-block;

        span {
            padding: 0;
            right: 0;
            width: 20px;
            height: 18px;
            background-position: -400px -436px;
            background-size: 900px 900px;

            &.glyphicon-chevron-down {
                background-position: -439px -436px;
            }

            &::before {
                display: none;
            }
        }
    }

    .FootdvSearch {
        padding: 0 2%;
    }

    .FooterWrap .FooterTop .footerStoreFinder::after {
        display: none;
    }

    .footerQuickLinks {
        padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 4) 0;
    }

    .FooterGroup {
        border: none;
    }

    .FooterGroup::before,
    .FooterGroup::after {
        content: none;
        border: none;
        display: none;
    }

    .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsStores a::before,
    .footerInfoSection #topLinkMenu ul.TopLinkMenu .iconsCS a::before {
        margin: 0;
    }

    .FooterGroupLink {
        width: 100%;
        margin: 0 auto;
        padding-bottom: calc(var(--spacing-unit) * 4);

        li {
            text-align: left;
        }

        li a {
            padding: calc(var(--spacing-unit) * 1.25) 0;
        }
    }

    .footerInfoSection {
        border: none;
    }

    .FooterWrap .FooterTop {
        .footerStoreFinder div::after {
            display: none;
        }

        .footerStoreFinder,
        .footerCustServ {
            text-align: center;
            padding-top: calc(var(--spacing-unit) * 2.5);
        }

        .footerCustServ::after {
            top: 15px;
            bottom: 0;
        }

        .footerStoreFinder div,
        .footerCustServ div {
            padding: 0;
        }
    }

    .signup-wrapper {
        margin-top: calc(var(--spacing-unit) * 4);

        .signupInner {
            padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 4);
        }

        .signupInner .join-us-text {
            margin-bottom: 0;
            padding-top: 0;
            width: 100%;
        }
    }

    .FooterSubGroup {
        border-bottom: solid 1px var(--site-light-grey);
        text-align: center;
    }
}

@media (min-width: 768px) {
    .FooterWrap .FooterTop {
        .footerStoreFinder,
        .footerCustServ {
            text-align: left;
        }

        .footerStoreFinder a,
        .footerCustServ a {
            display: block;
            position: relative;
            overflow: hidden;
        }

        .footerStoreFinder a::before,
        .footerCustServ a::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .footerStoreFinder a div,
        .footerCustServ a div {
            display: block;
            padding-left: 55px;
        }

        > div {
            display: table-cell;
            float: none;
            vertical-align: middle;
            padding: 0 3%;
        }

        > div.signup-wrapper {
            padding: 0 3% 0 2%;
        }
    }

    .footerContainer .footerMenu {
        display: flex;
        justify-content: space-between;
        padding: calc(var(--spacing-unit) * 10) calc(var(--spacing-unit) * 5);
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        &::before,
        &::after {
            display: none;
        }

        > div {
            width: auto;
        }
    }

    .Copyright .footerCopyLine {
        width: auto;
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        flex: 1 1 auto;
    }

    .FooterSubGroup {
        border-bottom: 0;
    }

    .FooterArea {
        margin-left: calc(var(--spacing-unit) * 5);
    }

    .footerMenu > .row {
        width: 25%;
        float: left;
    }

    .footerPane {
        width: 50%;
    }

    #topLinkMenu ul.TopLinkMenu .iconsStores a {
        border-left: 1px solid var(--site-mid-grey);
        border-right: 1px solid var(--site-mid-grey);
        padding-top: calc(var(--spacing-unit) * 5);
        padding-bottom: calc(var(--spacing-unit) * 5);
    }

    .footerLanguageSwitcher {
        width: 50%;
    }

    #topLinkMenu ul li.TopLink > a {
        flex-direction: row;
        padding: calc(var(--spacing-unit) * 5) 0;
    }
}

@media (min-width: 768px) and (max-width: 1050px) {
    .FooterWrap .FooterTop > div {
        padding: 0 calc(var(--spacing-unit) * 2.5);
    }

    .footerContainer .footerMenu {
        justify-content: space-around;
    }
}

@media (min-width: 1022px) {
    .FooterInner > div {
        margin-bottom: calc(var(--spacing-unit) * 2.5);
    }

    #topLinkMenu ul li.iconsCS a {
        justify-content: flex-start;
    }

    #topLinkMenu ul li.TopLinkDrop ul.TopSubLinkMenu {
        opacity: 1;
    }
}

#divCookieAcceptance {
    background-color: var(--site-black);
}

#cookieContentContainer {
    max-width: 2000px;
}

#divCookieAcceptance #inputAcceptCookies {
    background-color: var(--site-white) !important;
    height: calc(var(--spacing-unit) * 5);
    font-size: var(--body-font-size);
}

#divCookieAcceptance #cookieContentContainer p {
    color: var(--site-white);
    text-align: left;
    font-size: var(--body-font-size);
}

@media (min-width: 768px) {
    #divCookieAcceptance #cookieContentContainer p {
        text-align: center;
    }
}

FooterTop .signup-wrapper,
.MobileMenuContentWrap #divCurrencyLanguageMobile {
    display: none;
}
