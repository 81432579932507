.BasketNew {
    a.ContinueOn {
        background-color: var(--site-button-primary-background) !important;
        color: var(--site-button-primary-color) !important;

        &:hover {
            background-color: var(--site-button-primary-background-hover) !important;
            color: var(--site-button-primary-color-hover) !important;
        }

        &::after {
            background: var(--spritesheet) no-repeat !important;
            background-size: 600px 600px !important;
            background-position: -310px -291px !important;
        }
    }

    .BasketQuantBut {
        &::before {
            background-image: var(--spritesheet) !important;
            background-size: 600px 600px !important;
        }

        &.s-basket-minus-button::before {
            background-position: -287px -284px;
        }

        &.s-basket-plus-button::before {
            background-position: -260px -284px !important;
        }
    }

    .deleteItemIcon {
        background-image: var(--spritesheet) !important;
        background-size: 600px 600px !important;
        background-position: -361px -286px !important;
    }

    .MainOrderSummary {
        .PromoCodeBut {
            border: none;

            a {
                background-color: var(--site-black) !important;
                color: var(--site-white);

                &:hover {
                    background-color: var(--site-black) !important;
                    color: var(--site-white);
                }
            }
        }
    }
}

.Basket {
    h1,
    h2 {
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-regular);
    }

    #divContinueSecurely.ImgButWrap a,
    #divContinueSecurelyTop.ImgButWrap a {
        font-weight: var(--font-weight-regular);
    }
}

.BasketNew #BasketDiv table th.itemtotalprice .money,
.BasketNew .productdesc .competition-entry .terms,
.OrderSumm .TotalSumm span {
    font-weight: var(--font-weight-regular);
}

.global-icon-tick .tick-outer:not(.wishlist-share .global-icon-tick .tick-outer) {
    fill: var(--site-secondary);
    stroke-width: 0;
}

.global-icon-tick .tick-inner {
    fill: var(--site-white);
    stroke-width: 0;
}

.Basket .elevated-cart.elevated-cart-is-empty .cart-page__primary-button {
    background-color: var(--site-button-primary-background);
    color: var(--site-button-primary-color);
    font-weight: var(--font-weight-bold);
    border: var(--site-button-border-radius);
    text-transform: capitalize;
    letter-spacing: 0;
    border-radius: var(--site-border-radius);

    &:hover,
    &:focus {
        background-color: var(--site-button-primary-background-hover);
        color: var(--site-button-primary-color-hover);
    }
}

@media (min-width: 1024px) {
    .Basket .elevated-cart {
        margin-top: 16px;
    }
}

.product-line-card {
    .member-pricing .member-price-text,
    .member-pricing .member-price-value {
        font-family: var(--default-font-family);
        text-transform: capitalize;
        font-weight: var(--font-weight-bold);
    }
}

.basket-summary-container {
    .member-pricing {
        &.STUD {
            .member-price-text:not(small) {
                font-family: var(--default-font-family);
                text-transform: capitalize;
                font-weight: var(--font-weight-bold);
            }

            small {
                font-family: var(--default-font-family);
                font-weight: var(--font-weight-regular);
            }
        }
    }
}

.Basket .sitewide-banner-container,
.cms-sitewide-banner-enabled .sitewide-banner {
    z-index: 2;
    position: relative;
}
