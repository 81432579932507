html {
    overflow-x: hidden;
}

.watchshop-plp .mp-container,
.watchshop-plp .ContentWrapper {
    overflow: unset;
}

.watchshop-plp .catImage {
    order: 1;
}

.watchshop-plp #lblCategoryCopy,
.watchshop-plp .catTemplate,
.watchshop-plp #lblCategorySearchCopy {
    order: 3;
}

.watchshop-plp .siblingcategory,
.watchshop-plp .catDesc {
    padding: 0 16px;
    position: relative;
}

@media (min-width: 1022px) {
    .watchshop-plp .catDesc,
    .watchshop-plp .HeadnCount,
    .watchshop-plp #BreadcrumbGroup,
    .watchshop-plp .online-prices-differ-note,
    .watchshop-plp #FiltersAndProductsWrapper,
    .watchshop-plp #lblCategoryCopy,
    .watchshop-plp .siblingcategory,
    #CategoryAcross {
        padding-left: 40px;
        padding-right: 40px;
    }

    .watchshop-plp #lblCategorySearchCopy {
        padding-left: 40px;
    }

    #lblCategoryCopy .siblingLinks::-webkit-scrollbar-track,
    .topheadbox .siblingLinks::-webkit-scrollbar-track,
    #divsiblinglistwrapper ul.ulFirst::-webkit-scrollbar-track {
        margin-left: 40px;
        margin-right: 40px;
    }

    #lblCategoryCopy .catTemplate .siblingLinks,
    .topheadbox .siblingLinks,
    #divsiblinglistwrapper ul.ulFirst {
        white-space: normal;
        flex-wrap: wrap;
    }

    #lblCategoryCopy .catTemplate .siblingLinks a,
    .topheadbox .siblingLinks a,
    #divsiblinglistwrapper ul li a {
        margin-bottom: 4px;
    }

    .watchshop-plp .catDesc,
    .watchshop-plp .catTemplate-FWC {
        padding-left: 0;
        padding-right: 0;
    }

    .Browse
        #innerfiltercontainer
        .productFilters
        .productFilter.child-categories-in-filters
        + .productFilter.sort-by-in-filters {
        padding: calc(var(--spacing-unit) * 3.75) 0;
    }
}

.watchshop-plp .catTemplate-FWC .textDesc,
.mobile-read-more-less-enabled #lblCategoryCopy #read-more-less,
.mobile-read-more-less-enabled .topheadbox #read-more-less {
    font-size: 14px;
}

.watchshop-plp #lblCategorySearchCopy,
.watchshop-plp .catTemplate-FWC {
    order: 3;
    padding: 0 16px;
}

.watchshop-plp #lblCategorySearchCopy {
    padding-right: 0;
}

.watchshop-plp #lblCategorySearchCopy,
.watchshop-plp #CategoryResultLink {
    display: block;
    font-size: 14px;
    margin-top: 6px;
}

/* Sort Fix */
.watchshop-plp #divSortOptions {
    display: none;
}

/*--new sdl layout --*/

.watchshop-plp .layout li {
    height: 35px;
    padding: 0;
}

.watchshop-plp .layout li a {
    height: 100%;
    width: 30px;
}

.watchshop-plp .layout li.colopt3 a {
    background-position: -97px -20px;
}

.watchshop-plp .layout li.colopt3:hover a,
.watchshop-plp .layout li.colopt3.selected a {
    background-position: -97px -51px;
}

.watchshop-plp .layout li.colopt4 a {
    background-position: -131px -20px;
}

.watchshop-plp .layout li.colopt4 a:hover,
.watchshop-plp .layout li.colopt4.selected a {
    background-position: -131px -51px;
}

.layout li.colopt5 {
    display: none;
}

#lblSellingPrice.productHasRef,
.s-producttext-withticket .s-largered,
.productPrice .nowprice {
    color: var(--site-sale);
}

.Browse .s-productscontainer2 .TextSizeWrap .s-producttext-price.s-producttext-withticket .s-largered .curprice {
    font-weight: var(--font-weight-bold);
}

.Browse .discount-percentage-off {
    line-height: 20px;
}

.s-producttext-top-wrapper,
.columns3 .s-producttext-top-wrapper,
.columns4 .s-producttext-top-wrapper {
    padding: 5px;
}

.watchshop-plp .catTemplateNew .viewMore {
    padding-top: 15px;
    text-align: right;
}

.watchshop-plp .catTemplateNew .viewMore a {
    cursor: pointer;
}

.watchshop-plp .catTemplateNew .viewMore .collapsed span.ClosedView,
.watchshop-plp .catTemplateNew .viewMore a,
.watchshop-plp .catTemplateNew .viewMore span.OpenView {
    display: inline-block;
}

.watchshop-plp .catTemplateNew .viewMore a span {
    font-size: 1.2em;
    display: inline-block;
    vertical-align: top;
}

.watchshop-plp .catTemplateNew .viewMore .collapsed span.OpenView,
.watchshop-plp .catTemplateNew .viewMore span.ClosedView {
    display: none;
}

.watchshop-plp .catTemplateNew .viewMore span.OpenView::after,
.watchshop-plp .catTemplateNew .viewMore span.ClosedView::after {
    content: "";
    width: 17px;
    height: 17px;
    margin-left: 5px;
    vertical-align: top;
    display: inline-block;
    background-size: 720px 720px;
    background-position: -165px -374px;
}

.watchshop-plp .catTemplateNew .viewMore span.OpenView::after {
    background-position: -165px -402px;
}

.watchshop-plp .catTemplateNew .textDesc {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 10px;
    font-size: 1em;
}

.watchshop-plp .catTemplateNew .siblingLinks span {
    display: inline-block;
    border: 1px solid var(--site-black);
    border-radius: 25px;
}

.watchshop-plp .catTemplateNew .siblingLinks span a {
    letter-spacing: 0.1em;
    font-size: 1em;
    padding: 10px 15px;
    display: inline-block;
    transition: all 0.3s ease-out;
}

.watchshop-plp .catTemplateNew .siblingLinks span a:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .watchshop-plp .catTemplateNew .collapse {
        display: block !important;
        height: auto !important;
    }

    .catTemplate .textLeft {
        margin: 35px 0 15px;
        color: #999;
        line-height: 1.8em;
        padding: 0 5%;
        letter-spacing: 0.1em;
    }

    .catTemplate-FWC .siblingLinks a {
        padding: 12px 20px;
    }
}

/* PLP Category Template - FWC - Full Width with CTAs */
.catTemplate-FWC .topSection {
    padding: 0;
}

.catTemplate-FWC .textDesc {
    margin: 0;
    color: #333;
    font-size: 14px;
}

.catTemplate-FWC .siblingLinks {
    padding-top: 15px;
    font-size: 1em;
}

.catTemplate-FWC.inverted {
    background-color: #e1e1e1;
}

.catTemplate-FWC.inverted .siblingLinks a {
    background-color: var(--site-white);
}

.catTemplate-FWC.inverted .siblingLinks a:hover {
    background-color: #f1f1f1;
}

.catTemplate-FWC .imgRight a {
    display: block;
}

.catTemplate-FWC .imgRight img {
    margin: 0;
}

.watchshop-plp .dropprods_Order {
    max-width: 70%;
    width: auto;
    font-size: 1.2em;
    padding: 0 8px;
    min-width: 200px;
}

.watchshop-plp.has-member-pricing-product-on-plp {
    .plp-advert-placement {
        order: 3 !important;
    }
}

@media (min-width: 1022px) {
    /* New PLP '19 */
    #innerfiltercontainer {
        padding: 0 10px 0 0;
    }
}

@media (max-width: 767px) {
    .watchshop-plp.Browse #lblCategoryCopy,
    .watchshop-plp.Browse .topheadbox {
        display: block;
    }

    #lblCategoryCopy .catTemplate .textLeft {
        padding: 0 10px;
    }

    #lblCategoryCopy .catTemplate .siblingMore {
        margin-top: 10px;
    }

    .watchshop-plp.has-member-pricing-product-on-plp {
        .plp-advert-placement {
            order: 1 !important;
        }
    }
}

/* Sports Direct Elevation 22 */

@media (max-width: 1021px) {
    #Body .s-productthumbbox .hotspotquickbuy {
        display: none !important;
    }

    #Body .s-productthumbbox .hotspotwishlist {
        display: block !important;
    }

    .watchshop-plp.has-member-pricing-product-on-plp {
        .plp-advert-placement {
            order: 2 !important;
        }
    }
}

.watchshop-plp #ProductContainer {
    border-top: 0;
    width: 100%;
}

@media (min-width: 1022px) {
    .watchshop-plp #ProductContainer {
        padding-left: 30px;
    }
}

.watchshop-plp .glyphicon-ok-sign::before {
    background-position: -240px -417px;
}

.watchshop-plp .ChildCategoriesListWrapper {
    padding: 0 16px;
}

.ChildCategoriesListWrapper #ChildCategoriesList {
    padding-right: 10px;
}

.watchshop-plp .ChildCategoriesListWrapper #ChildCategoriesList li {
    min-height: 28px;
    margin-bottom: 5px;
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.watchshop-plp .ChildCategoriesListWrapper #ChildCategoriesList li a {
    width: 100%;
    padding: 0;
    font-size: var(--body-font-size);
    color: var(--site-primary);
    line-height: 20px;
}

.ChildCategoriesListWrapper h2 {
    padding: 0;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: left;
    font-size: var(--body-font-size);
    color: var(--site-primary);
    text-transform: capitalize;
}

@media (min-width: 1022px) {
    .watchshop-plp .ChildCategoriesListWrapper {
        padding: 22px 0;
        border-bottom: 1px solid var(--site-mid-grey);
        margin-bottom: 14px;

        h2 {
            padding: 0;
        }
    }
}

.watchshop-plp #spnSearchIcon .glyphicon {
    font-size: 11px;
    font-family: initial;
    background-size: 700px 700px;
    background-position: -274px -379px;
    height: 30px;
    width: 30px;
}

.watchshop-plp #spnSearchIcon .glyphicon::before {
    content: none;
}

/* SD LV8 PLP Header Changes */

.watchshop-plp .ContentWrapper,
.Checkout .ContentWrapper {
    padding: 0;
    max-width: 1440px;
}

.watchshop-plp .ContentWrapper,
.watchshop-plp #lblCategoryCopy,
.watchshop-plp .catTemplate,
.watchshop-plp .topheadbox,
.Checkout .ContentWrapper {
    max-width: 100%;
}

.watchshop-plp .ImgTxtContainer,
.watchshop-plp .BreadcrumbGroupWrapper {
    text-align: left;
}

.watchshop-plp #BreadcrumbGroup {
    padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 4);
}

@media (max-width: 1021px) {
    .watchshop-plp #ProductContainer {
        padding: calc(var(--spacing-unit) * 4);
    }
}

.watchshop-plp .topheadbox,
.watchshop-plp #BreadcrumbGroup,
.watchshop-plp #FiltersAndProductsWrapper {
    max-width: var(--default-container-max-width);
    margin: 0 auto;
    float: none;
}

.watchshop-plp .s-breadcrumbs-container {
    margin: 0;
}

.categorycopyd4,
.col-xs-12.FilterProductsTable {
    padding: 0;
}

.online-prices-differ-note {
    padding: 0 16px;
}

#divsiblinglistwrapper ul li:last-of-type a,
#lblCategoryCopy .catTemplate .siblingLinks span:last-of-type a,
.topheadbox .siblingLinks span:last-of-type a {
    margin: 0 0 10px;
}

#CategoryAcross {
    padding: 10px 16px;
}

@media (max-width: 767px) {
    #lblCategoryCopy .catTemplate .siblingLinks a,
    .topheadbox .siblingLinks a,
    #divsiblinglistwrapper ul li a {
        background-color: var(--site-white);
    }
}

@media (min-width: 1640px) {
    .Browse .topheadbox,
    .Browse #BreadcrumbGroup,
    .Browse #FiltersAndProductsWrapper {
        max-width: var(--container-max-width);
    }
}

#prdsFound {
    span {
        font-size: var(--body-font-size);
        color: var(--site-dark-grey);
    }

    @media (min-width: 1022px) {
        display: block;
    }
}
