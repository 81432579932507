@font-face {
    font-family: Figtree;
    src: url("/Fonts/Figtree-Regular.woff2") format("woff2");
}

@font-face {
    font-family: Figtree;
    src: url("/Fonts/Figtree-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Figtree;
    src: url("/Fonts/Figtree-Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
