.mp-menu > .mp-level ul {
    li.newTag .menuitemtext,
    li.saleTag .menuitemtext,
    li.hotTag .menuitemtext {
        &::after {
            padding: var(--spacing-unit);
            vertical-align: text-bottom;
            font-size: var(--body-font-size);
            line-height: 1em;
            color: var(--site-white);
            display: inline-block;
        }
    }

    li.saleTag .menuitemtext::after {
        content: "SALE";
        background: var(--site-sale);
    }

    li.hotTag .menuitemtext::after {
        content: "HOT";
        background: var(--site-sale);
    }

    li.newTag .menuitemtext::after {
        content: "NEW";
        background: var(--site-primary);
    }
}

.mp-container .mp-menu {
    .mp-level #homeMenu li > a.mp-close,
    .mp-level ul > li a.mp-close {
        display: none;
    }

    .mp-level {
        max-height: 100%;
        z-index: 2;
    }

    .mmHasChild .mp-level.show-level .mobMenGroup {
        background-color: var(--site-white);
        display: block;
    }
}

#mp-menu .mmHasChild.flexReorder > .mp-level > .mobMenGroup {
    display: flex;
    flex-wrap: wrap;

    > li {
        width: 100%;
        order: 1;

        &.leftHeader {
            order: 2;
            margin-top: 0;
        }

        &.leftHeader.altHeader {
            margin-top: calc(var(--spacing-unit) * 5);
        }

        &.mobMenToplink {
            width: auto;
        }
    }
}

.mobMenuGroup #mob-myaccount .menulevelheader a {
    color: var(--site-white);
}

.mobMenuGroup li.root .mp-level > .menulevelheader {
    text-align: center;
    padding-left: calc(var(--spacing-unit) * 2.5);
}

#mp-menu .mp-level {
    background-color: var(--site-white) !important;
    height: 100%;
    top: 0;

    .level1 .mp-level {
        top: 0;
        height: 100%;
    }

    .menuitemtext {
        color: var(--site-black);
        font-size: var(--subtitle-font-size);
        box-shadow: none;
        cursor: pointer;
        border-bottom: 1px solid var(--site-light-grey);

        > img {
            background-color: var(--site-lightest-grey);
            height: 40px;
            width: 40px;
            object-fit: cover;
            border-radius: calc(var(--site-border-radius) + 1px);
            margin-right: calc(var(--spacing-unit) * 2.5);
            display: inline-block;
            overflow: hidden;
        }
    }

    .account-menu a {
        text-transform: none;
    }

    .shopbySize > a::before,
    .shopbySize > .mp-level > p > a::before {
        content: "Shop by Size - ";
    }

    .MenuGroupOutlet > .mp-level > ul {
        display: flex;
        flex-direction: column;

        > .sdmColGap {
            margin-top: 0;
        }

        > li {
            order: 5;
        }

        .mens-sale-menu {
            order: 1;
        }

        .womens-sale-menu {
            order: 2;
        }

        .kids-sale-menu {
            order: 3;
        }

        .size-sale-menu {
            order: 4;
        }

        .size-sale-menu > a::before,
        .size-sale-menu p.menulevelheader > a::before {
            content: "Sale by ";
        }
    }

    &.mp-level.show-level {
        overflow-y: auto;
    }

    &.show-level.child-open {
        overflow-y: hidden;
    }

    .MenusaleRed,
    .MenusaleRed > .menuitemtext.MobMenChevron {
        background-color: var(--site-sale);
        color: var(--site-white);
    }

    .MenusaleRed > a.MobMenChevron::after {
        content: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2211%22%20height%3D%2219%22%20viewBox%3D%220%200%2011%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1.39111%2017.5957L9.26904%209.71777L1.39111%201.83984%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20%2F%3E%3C%2Fsvg%3E");
        top: 50%;
        translate: 0 -50%;
        background: none;
    }
}

#mp-menu > .mp-level {
    top: 0;
    height: 100%;

    #divCurrencyLanguageMobile #liMobileCurrencySelector {
        margin-bottom: 0;
        background-color: var(--site-lightest-grey);
    }

    #divCurrencyLanguageMobile a,
    #divCurrencyLanguageMobile .currencySelectorMobile > li > .currencyOption {
        margin-left: 0;
        padding-left: calc(var(--spacing-unit) * 6.25) !important;
        width: 100%;
    }
}

.mp-menu ul li:last-of-type > a,
.mp-menu .shop ul li.MenuGroupOutlet > a,
.mp-menu .mobile-menu-footer-content > ul > li.has-dropdown > a.open,
.mp-menu .mobile-menu-footer-content #divCurrencyLanguageMobile ul > li.has-dropdown > a.open {
    box-shadow: none;
}

.MobileMenuContentWrap {
    display: flex;
    flex-direction: column;

    .menu-header {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 60px;
    }

    #divCurrencyLanguageMobile {
        order: 1;

        .collapsable-item::before,
        .collapsable-item::after {
            background: var(--site-primary);
            background-position: center;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: unset;
            top: 50%;
            right: 27px;
            translate: 0 -50%;
            width: 14px;
            height: 2px;
            padding: 0;
            box-sizing: border-box;
            content: "";
            transition: rotate 0.2s ease-in-out;
        }

        .collapsable-item.collapsed::before {
            rotate: -90deg;
        }
    }
}

.menu-trigger {
    background: none;
    height: 60px;
    width: 60px;
    text-indent: 0;
    line-height: 60px;
    text-align: center;
    float: none;
    background-color: transparent !important;

    @media (min-width: 768px) {
        line-height: 65px;
    }

    .body-menu-open &::before {
        background-position: -193px -380px;
    }

    .global-icon-cross svg {
        width: 24px;
        height: 24px;

        path {
            stroke-width: 1px;
        }
    }

    &:hover {
        background-color: transparent !important;
    }
}

.body-menu-open {
    .global-icon.global-icon-menu {
        display: none;
    }
}

.mobile-menu-icons {
    display: block;
    height: 100%;
    pointer-events: none;

    .global-icon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 25px;
            height: 24px;
            margin-top: 7px;
        }
    }

    .menu-icon-top,
    .menu-icon-middle,
    .menu-icon-bottom {
        transition: all 0.2s ease-in-out;
        opacity: 1;
        width: 25px;
        x: 0;
    }

    .menu-icon-top {
        transform-origin: top left;
    }

    .menu-icon-bottom {
        transform-origin: bottom left;
    }

    .menu-icon-middle {
        transform: rotate3d(1, 1, 1, 0deg);
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
    }

    .PullMenuActive & {
        .menu-icon-top {
            rotate: 45deg;
            width: 25px;
            x: 2;
            y: -1.8;
        }

        .menu-icon-middle {
            transform: rotate3d(0, 1, 0, -90deg);
        }

        .menu-icon-bottom {
            rotate: -45deg;
            width: 25px;
            x: 2;
            y: 19;
        }
    }
}

@media (max-width: 767px) {
    #mp-menu {
        height: calc(100% - 120px);
        width: 100%;
        z-index: 5895 !important;
        top: 120px !important;
        max-width: none;
        border-top: 1px solid var(--site-light-grey);

        .mp-level .mp-level .mp-level .MobMenChevron {
            margin: 0;
            width: 100%;
        }

        .mp-level .mp-level .mp-level .sdmColHeader > .MobMenChevron {
            font-weight: var(--font-weight-regular);
            padding-left: calc(var(--spacing-unit) * 6.25) !important;
        }

        ul li.mobMenToplink {
            position: absolute;
            margin: 0;
            top: 18px;
            right: 25px;
            width: auto;
            background-color: transparent;
            height: auto !important;
        }

        ul li.mobMenToplink a.menuitemtext {
            color: var(--site-black);
            padding: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            background-color: transparent;
            font-size: 1.2em;
            border-bottom: 2px solid var(--site-black);
            line-height: 1.4em;
            margin: 0;
            width: auto;
        }
    }

    .PullMenuActive .MenuCloseActive,
    .body-menu-open .MenuCloseActive {
        right: 0;
    }

    .ToplinksGroup .container-fluid > .row > div {
        background-color: var(--site-white);
        height: 60px;
    }

    .ToplinksGroup .container-fluid .ControlWrap {
        padding: 0;
        float: left;
        width: 120px;
        background-color: transparent !important;
    }

    #mobMenuContainer {
        width: 55px;
        padding: 0;
    }

    #mobMenuContainer {
        padding-right: 0;
    }

    .menu-trigger,
    .ControlWrap .WishList,
    .ControlWrap .accountBlock,
    .Responsive #divBag {
        width: 50px;
    }

    #divBagTotalLink a#aBagLink {
        min-width: 50px;
    }

    .accountBlock #topLinkMenu .TopLinkDrop .TopSubLinkMenu {
        display: none !important;
    }

    .shop {
        .has-dropdown > a.MobMenChevron::after {
            top: 50%;
            translate: 0 -50%;
            background: none;
            content: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2211%22%20height%3D%2219%22%20viewBox%3D%220%200%2011%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1.39111%2017.5957L9.26904%209.71777L1.39111%201.83984%22%20stroke%3D%22%2300003c%22%20stroke-width%3D%222%22%20stroke-miterlimit%3D%2210%22%20%2F%3E%3C%2Fsvg%3E");
        }
    }

    .account-menu {
        background-color: var(--site-lightest-grey);

        .MobileMenuContentWrap & {
            padding: 0;
        }

        .mobile-menu-footer > & {
            padding: calc(var(--spacing-unit) * 2) 0 calc(var(--spacing-unit) * 3);
        }

        .panel {
            background-color: transparent;
            box-shadow: none;
            margin: 0;
        }

        .has-dropdown > a.MobMenChevron {
            &::before,
            &::after {
                background: var(--site-primary);
                background-position: center;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: unset;
                top: 50%;
                right: 27px;
                translate: 0 -50%;
                width: 14px;
                height: 2px;
                padding: 0;
                box-sizing: border-box;
                content: "";
            }

            &::before {
                rotate: -90deg;
                transition: rotate 0.2s ease-in-out;
            }

            &.open::before {
                rotate: 0deg;
            }
        }
    }

    #mobile-menu-footer .account-menu .mobMenuGroup.account-menu,
    .currencySelectorMobile li {
        background-color: var(--site-white);
    }
}

@media (max-width: 359px) {
    .ControlWrap .WishList {
        width: 0;

        #aWishListLink {
            display: none;
        }
    }
}

@media (max-width: 1021px) {
    .MobileMenuContentWrap {
        background-color: var(--site-light-grey);

        .mobile-menu-footer-content {
            .shop {
                background-color: var(--site-light-grey);

                li a {
                    background-color: transparent;
                    box-shadow: none;
                }
            }
        }

        .shop {
            background-color: var(--site-white);

            .mobMenuGroup {
                padding: 0;
            }
        }

        #topLinkMenu ul li.TopLink > a > .ico {
            background-position: -9px -341px;
            width: 50px;
            height: 50px;
        }
    }
}
