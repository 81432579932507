.is-cms-content-page .ContentWrapper {
    padding: 0;
}

.cms-placements {
    display: block;
    position: relative;
    max-width: 1920px;
}

body {
    box-sizing: border-box;
}

#__next,
.cms-placements {
    #content-x div {
        &[data-testid="container"],
        &[data-testid="club-finder-block"],
        &[data-testid="tabbed-quick-links-block"],
        &[data-testid="brand-block-container"],
        &[data-testid="cards-block"] {
            margin: auto;
        }
    }

    a,
    a:active,
    a:focus,
    a:link,
    a:visited,
    a:hover,
    a p:hover,
    h1,
    h1 span,
    h2,
    h3,
    h4,
    h5,
    p,
    label,
    input,
    optgroup,
    select,
    textarea {
        color: var(--color);
    }

    div[data-testid="markdown-block"] img {
        max-width: 100%;
    }

    ul,
    ul li {
        list-style: initial;
    }

    ol,
    ol li {
        list-style: decimal;
    }

    ul,
    ol {
        padding-left: var(--spacing-4, 16px);
    }

    button {
        &:not([data-testid]) {
            color: var(--color);
            background-color: var(--bg-color);
            border-color: var(--border-color);

            &:hover {
                background-color: var(--bg-color, transparent);
            }
        }

        &[data-testid] {
            &[data-testid="tabbed-quick-links-block-button"]:hover {
                color: unset;
                background-color: unset;
            }

            &[data-testid="accordion-group"] {
                margin: 0 12px;
                border-top: 1px solid var(--grey-300);

                @media (min-width: 1022px) {
                    margin: 0;
                }
            }

            &[data-testid="accordion-summary"] {
                border-color: var(--grey-300);
                background-color: var(--common-white) !important;

                &:hover {
                    color: unset;
                    background-color: unset;
                }
            }
        }
    }
}
