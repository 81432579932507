.watchshop-pdp .colourImages li a {
    border: 1px solid var(--site-mid-grey);
}

.watchshop-pdp .colourImages li a:hover,
.watchshop-pdp .colourImages li a:focus {
    border: 1px solid var(--site-primary);
}

.watchshop-pdp .colourImages {
    transition: height 0.3s ease-in-out;
    height: auto;
    overflow: hidden;
}

.watchshop-pdp .colourImages li {
    width: 60px;
    height: 60px;
    margin: 0;
    padding: var(--spacing-unit);

    @media (max-width: 425px) {
        width: 70px;
        height: 70px;
    }
}

.watchshop-pdp .colourImages li.swiper-slide {
    height: auto;
}

.watchshop-pdp .colourImages li.variantHighlight a {
    border: 1px solid var(--site-secondary);
    border-radius: var(--site-button-border-radius);
    outline: 1px solid var(--site-secondary) !important;
    outline-offset: -2px;

    &::before {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background: color-mix(in srgb, var(--site-light-grey) 50%, transparent);
        top: 0;
        left: 0;
    }
}

.watchshop-pdp .colourImages li.variantHighlight::before {
    display: none;
}

.watchshop-pdp .colourImages li.greyOut span {
    background: #f8f8f8;
    opacity: 0.85;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
}

.watchshop-pdp .colourImages li a,
.watchshop-pdp .colourImages li.greyOut a {
    border: 1px solid var(--site-light-grey);
    border-radius: var(--site-button-border-radius);
}

.watchshop-pdp .colourImages li a .overlayColorway {
    cursor: pointer;
    background: rgba(231, 235, 237, 0.9);
    border-radius: var(--site-button-border-radius);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--site-black);
    font-size: var(--body-font-size);
}

#productDetails .colourImages li.greyOut.variantHighlight a,
#productDetails .sizeButtons li.sizeVariantHighlight.greyOut a {
    border-color: var(--site-dark-grey) !important;
}

#productDetails .sizeButtons li.sizeVariantHighlight.greyOut a::before {
    content: none;
}

.watchshop-pdp .colourImages.expandingColorways {
    max-height: 116px;
}
.watchshop-pdp .colourImages.expandingColorways.expandColourways .overlayColorway {
    display: none;
}

.colourImages li img {
    max-width: none;
}
