#innerfiltercontainer,
#divulBagParent,
.popupFullscreen .modal-body {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background: var(--site-light-grey);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--site-primary);
        border-radius: 5px;
    }
}

.order-summary .product-gallery .swiper-scrollbar .swiper-scrollbar-drag {
    background-color: var(--site-black);
}
