.product-line-card {
    a,
    p,
    label,
    &__description-remove-button {
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-regular);
        color: var(--site-primary);
    }

    &__description-line-error-message {
        color: var(--wishlist-line-error-color);
    }

    &__description-sizes select {
        border: 1px solid var(--site-mid-grey);
    }

    &__description-sizes select option {
        font-size: var(--subtitle-font-size);
        font-weight: var(--font-weight-regular);
    }

    &__description-remove button {
        align-items: center;
    }

    &__description-pre-order {
        font-weight: var(--font-weight-bold);
        color: var(--site-primary);
    }

    &__description-age-restriction {
        color: var(--site-primary);
    }

    &__description-discount {
        color: var(--product-line-item-line-error-color);
    }

    &__description-low-stock-message {
        .global-icon svg path {
            fill: var(--product-line-item-line-error-color);
        }
    }

    &__quantity-button {
        color: var(--site-primary);
        font-size: calc(var(--body-font-size) * 1.5);
        font-weight: var(--font-weight-semi-bold);
        border-radius: var(--site-border-radius);

        &:hover {
            color: var(--site-primary) !important;
            background-color: var(--site-white) !important;
            border: 1px solid var(--site-primary);
        }

        &:focus {
            color: var(--site-primary) !important;
            background-color: var(--site-secondary-lighter) !important;
            border: 1px solid var(--site-primary);
        }
    }

    &__quantity {
        border-radius: var(--site-border-radius);
        border: 1px solid var(--site-mid-grey);
    }

    &__description-move button .button-icon svg,
    &__description-remove button .button-icon svg {
        width: 20px;
        height: 24px;
        stroke-width: 0;
    }
}
