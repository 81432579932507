.Browse #divPagination {
    display: flex;
    align-items: center;

    .disablePaginationArrow,
    .PrevLink,
    .NextLink {
        display: flex;
        align-items: center;
        justify-content: center;
        text-indent: -9999px;
        height: 48px;
        line-height: 35px;
        background-size: 1000px 1000px;
        width: 30px;
        border: 0;
        background-position: -523px -471px;
        background-image: var(--spritesheet);
    }

    .PrevLink {
        transform: rotate(180deg);
    }

    .disablePaginationArrow .global-icon path {
        stroke: var(--site-mid-grey);
    }

    .PrevLink .global-icon {
        rotate: 180deg;
    }

    .paginationEllipsis {
        line-height: 35px;
    }
}

.pagination .PageNumber .PageNumberInner {
    display: flex;
}

#TopPaginationWrapper {
    display: none;
}

.paginationWrapper {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 1022px) {
        padding: 0;
        width: 100%;
        left: 0;
    }

    #TopPaginationWrapper.activeFilterBar & {
        position: relative;
        z-index: 1;
        width: 100% !important;
    }

    .pagination {
        .PageNumber {
            a.PrevLink,
            .CurrentPageNumber,
            .PageSeperator,
            .MaxPageNumber,
            a.NextLink {
                text-transform: none;
                font-weight: var(--font-weight-regular);
                font-size: var(--body-font-size);
                height: 48px;
                display: flex;
                align-items: center;
            }

            .PageSeperator {
                margin: 0 10px;
            }

            a.PrevLink,
            a.NextLink,
            a.PrevLink:hover,
            a.NextLink:hover {
                position: relative;
                width: auto;
                border: none;
                color: var(--site-black);
            }

            a.PrevLink:hover,
            a.NextLink:hover {
                text-decoration: underline;
            }

            a.PrevLink.DisabledLink,
            a.NextLink.DisabledLink {
                opacity: 0.4;
            }
        }

        #TopPaginationWrapper & {
            padding: 0 5px;
            margin: 0;
            width: 100%;

            @media (min-width: 1022px) {
                padding: 0;
            }

            .Browse & .layout {
                text-align: left;
            }

            .PageNumber {
                display: flex;
                justify-content: flex-end;
            }

            @media (max-width: 1021px) {
                padding: 0;
                flex-direction: column;
            }
        }
    }
}

.Browse .layout span.ViewbyCols,
.Browse .pagination .sortbyfilter label {
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.PageFromTo {
    border-bottom: 1px solid var(--site-light-grey);
    border-right: none;
    padding: calc(var(--spacing-unit) * 2) 0;
    margin-right: calc(var(--spacing-unit) * 4);
    height: auto;
    float: none;

    @media (min-width: 1022px) {
        &.mobilePageFromTo {
            display: none;
        }
    }

    .topheadbox & {
        font-size: 18px;
        margin: 0;
        padding: 0;

        .pppt,
        .pppt span {
            font-size: 16px;
            line-height: 18px;
            font-style: normal;
            letter-spacing: normal;
        }

        .pppt::before {
            content: "(";
            display: inline-block;
        }

        .pppt::after {
            content: ")";
            display: inline-block;
            margin-left: -4px;
        }

        .productsCountText {
            display: none;
        }

        @media (min-width: 768px) {
            .productsCountText {
                display: inline-block;
            }

            .pppt::after {
                margin-left: 0;
            }
        }
    }

    .pagination & {
        .pppt,
        .pppt span {
            font-size: var(--body-font-size);
            line-height: 40px;
            font-weight: var(--font-weight-regular);
            color: var(--site-black);
        }
    }

    &.mobilePageFromTo {
        display: flex;
        margin-bottom: 0;
        float: none;
    }
}

.BottomPaginationWrapper {
    overflow: hidden;

    &::before,
    &::after {
        content: "";
        display: table;
    }
}

#FiltersHeader .PageFromTo.mobilePageFromTo {
    margin-left: 0;
    margin-top: 4px;
    text-transform: none;
    height: 14px;
    padding: 0;
}

#BodyWrap .PageNumber .swipeNumberClick,
.PageNumber span {
    background: none;
    color: var(--site-black);
    font-size: var(--body-font-size);
}

#BodyWrap .PageNumber span.PageSelector {
    color: var(--site-primary);
    font-weight: var(--font-weight-bold);
    font-size: var(--body-font-size);
    background-color: var(--site-secondary);
    border-radius: var(--site-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 24px;
    padding-bottom: 1px;
}

@media (min-width: 768px) {
    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 12px;
    }
}

@media (max-width: 374px) {
    .PageNumber a,
    .PageNumber span.PageSelector {
        padding: 0 4px;
    }
}
