.watchshop-pdp .ContentWrapper,
.watchshop-pdp .BreadcrumbGroupWrapper,
.watchshop-pdp .sdPageProductContainer {
    background: var(--site-white);
}

.watchshop-pdp {
    max-width: 100%;
}

.watchshop-pdp .productImageContentWrapper {
    position: relative;
}

.watchshop-pdp .ContentWrapper {
    margin: 0 auto !important;
    padding: 0;
    padding-bottom: calc(var(--spacing-unit) * 11.5);

    .BreadcrumbGroupWrapper,
    .ResponsiveProductDetail {
        padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 4) 0;
        max-width: var(--container-max-width);
        margin: 0 auto;

        @media (min-width: 1022px) {
            padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 10) 0;
        }

        @media (min-width: 1640px) {
            padding: calc(var(--spacing-unit) * 2.5) 0 0;
        }
    }

    .s-breadcrumbs-bar {
        padding: 0;
    }

    .s-basket-quantity-text-box input:focus,
    .s-basket-quantity-text-box input:hover {
        border: none;
    }

    @media (max-width: 767px) {
        .ResponsiveProductDetail {
            padding: 0;
        }

        .ProdDetLeft {
            padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 5.25) 0;
        }
    }
}

@media (min-width: 768px) {
    .watchshop-pdp .productImageContentWrapper {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }

    .watchshop-pdp #productImages {
        width: 60%;
        padding: 0;
    }

    .watchshop-pdp #productDetails,
    .promo-header-pdp {
        width: 40%;
        padding: 0;
    }

    .promo-header-pdp {
        padding-right: 0;
    }
}

@media (min-width: 1022px) {
    .watchshop-pdp #productImages {
        padding: 0;
    }

    .watchshop-pdp #productDetails {
        padding-bottom: calc(var(--spacing-unit) * 10);
    }
}

@media (min-width: 1440px) {
    .watchshop-pdp #productImages {
        width: 70%;
    }

    .watchshop-pdp #productDetails {
        width: 30%;
        max-width: 385px;
        float: none;
        padding: 0 calc(var(--spacing-unit) * 2.5);
        margin: 0 auto;
        overflow: hidden;
        position: relative;
    }
}

.watchshop-pdp #productDetails .productVariantContainer {
    .colourChooser,
    .ColourImagesWrap,
    .SzQuantGroup,
    #availableFromContainer,
    .BasketWishContainer {
        padding: 0;
    }

    .colourChooser {
        padding-bottom: calc(var(--spacing-unit) + 1);
        margin-top: calc(var(--spacing-unit) * 2);
    }

    #availableFromContainer {
        padding-top: calc(var(--spacing-unit) * 2.5);
    }
}

.personalisation,
.s-breadcrumbs-container .s-breadcrumbs-bar,
#BuySizeText,
.s-basket-quantity-text-box input {
    color: var(--site-primary);
}

.s-breadcrumbs-bar > span ol li a:focus,
.s-breadcrumbs-bar > span ol li a:hover {
    color: var(--site-primary);
    text-decoration: underline;
}

.s-breadcrumbs-bar > span ol li {
    color: var(--site-primary);
    padding: 0;
}

.s-breadcrumbs-bar a,
.s-breadcrumbs-bar a:active,
.s-breadcrumbs-bar a:focus,
.s-breadcrumbs-bar a:hover,
.s-breadcrumbs-bar a:visited {
    color: var(--site-primary);
}

.stock-level-container {
    border-radius: 0;
    margin: 0 0 calc(var(--spacing-unit) * 6) 0;
}

@media (max-width: 767px) {
    #BodyWrap .ContentWrapper {
        padding-bottom: calc(var(--spacing-unit) * 16);
    }

    .watchshop-pdp .productImageContentWrapper {
        display: flex;
        flex-direction: column;
    }

    .watchshop-pdp .productImageContentWrapper > div {
        order: 2;
    }

    .watchshop-pdp .productImageContentWrapper .promo-header-pdp {
        order: 1;
    }
}

.RadTabStrip.RadTabStrip_Vista_Dark .rtsLevel .myIdRtsSelected .rtsIn {
    background-color: var(--site-primary) !important;
}

.myIdPersonalisationContainer #myIdTabs .RadTabStrip .rtsIn,
.myIdTabBody {
    border: 1px solid var(--site-primary);
}

#productDetails .TrueFitWrapper iframe {
    margin: 10px 0 15px;
}

#productDetails .discount-percentage-off {
    vertical-align: middle;
}

#productDetails .myIdPersonalisation {
    input,
    select {
        border-color: var(--site-primary);
        padding: 10px;
    }
}

.RadTabStrip .rtsLevel .rtsIn,
.RadTabStrip .rtsLevel .rtsIn:hover {
    color: var(--site-primary) !important;
}

.watchshop-pdp #productDetails .rating-container .bv_numReviews_text {
    color: var(--site-primary);
}

#productDetails .productVariantContainer .StudioPayContainer {
    padding: 0;
}

.discount-percentage-off,
#hsDiscountPercentText {
    font-family: var(--default-font-family);
}

.AltProdDet #MoreFromLinks {
    border: none;
    padding: 0 5px;
    margin: 8px 0 0;

    ul {
        margin-left: 10px;
    }

    .MoreFromLinksHeader {
        background: none;
    }
}
