.watchshop-pdp {
    #pnlRecentlyViewedProducts {
        margin: 0 0 45px;
        padding-top: 25px;

        @media (min-width: 1920px) {
            max-width: 1920px;
            margin: 0 auto 45px;
        }

        h4 {
            padding-top: calc(var(--spacing-unit) * 6);
        }

        .AltVertOne {
            padding-top: 25px;
            padding-left: calc(3% + 16px);
            padding-right: calc(3% + 16px);
            padding-bottom: 10px;

            @media (min-width: 768px) {
                padding-left: 15px;
                padding-right: 15px;
            }

            @media (min-width: 1024px) {
                padding-left: calc(5% + 24px);
                padding-right: calc(5% + 24px);
            }
        }

        .RvStratImgQuick {
            margin-bottom: 13px;
        }

        .AltStratBrand,
        .AltStratDescription,
        .AltStratDescription span {
            color: var(--site-primary);
            font-size: var(--subtitle-font-size);
            font-weight: var(--font-weight-regular);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            padding: 0;
            line-height: 20px;
        }

        .AltStratRefSell span {
            font-weight: var(--font-weight-regular);
            font-size: var(--body-font-size);
            line-height: 20px;
        }

        .AltFromSell:has(+ #divRefPrice) {
            span {
                font-weight: var(--font-weight-bold);
                font-size: var(--body-font-size);
                line-height: 20px;
                padding: 2px 0;
                color: var(--site-sale);
            }
        }

        .AltFromSell + #divRefPrice {
            span {
                color: var(--site-primary);
            }
        }

        .AltStratBrandDesc {
            padding: 0;
        }

        .hotspotbuy.hotspotquickbuy,
        .hotspotbuy.hotspotwishlist {
            display: none !important;
        }
    }
}
