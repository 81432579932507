#Body,
p,
a,
div,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
input,
textarea,
html,
blockquote,
th,
td,
tr,
legend,
select,
input[type="submit"],
button,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--default-font-family), Arial, Helvetica, sans-serif;
}

.maxText,
.saleprice,
.SubBask .PriceTotalBask,
.saleTime .s-producttext-withticket .s-largered,
#productDetails #infoTabs .infoTabPage a:hover,
h6,
.csHomeTable a:hover,
.SubTotal,
.csHomeTable li.header a:hover,
.BaskName:hover,
.saleTime .AltStratSellPrice.productHasRef,
.lillBasket #ulBag li .lineProblems span,
.lillBasket #ulBag li .maxText,
.yousave,
.nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
.saleTime .pdpPrice .productHasRef,
.saleTime #hsPrice,
.saleTime .PSProdPrice .PSSellPrice.productHasRef {
    color: #ed0000;
}

.discountText,
.preorder,
#FootcmdSearch {
    color: #bfb7b0;
}

#dvCancelled,
#dvInProgress,
#dvNewOrder,
#dvRejected,
#dvShipped,
#dvSubmitted {
    color: #bfb7b0 !important;
}

a,
a:active,
a:focus,
a:link,
a:visited,
.RightBox p,
.RightBox h3,
.RightBox,
.TopHeader p,
a:hover,
a p:hover {
    color: #636363;
}

.RadTabStrip .rtsLevel .rtsIn:hover,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
.LeftBarLinks #topLinkMenu ul.TopLinkMenu li,
.RadTabStrip .rtsLevel .rtsIn {
    color: #333 !important;
}

.Basket #BasketDiv table th,
.pppt,
.sizeDetail,
#productImages .imageControls .zoomControl,
#productImages .imageControls .spinControl,
.currentPage {
    color: #666;
}

.fromProductPrice {
    color: #888;
}

#securedByLogin span {
    color: #777 !important;
}

.originalprice,
input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled,
input[type="file"]:disabled::-webkit-file-upload-button,
button:disabled,
select:disabled,
keygen:disabled,
optgroup:disabled,
option:disabled,
.BaskColr,
.BaskSize,
.s-smalltext,
.s-producttext-price .AdditonalPriceLabel {
    color: #888;
}

.Basket #BasketDiv .productcode span,
.ToplinksGroup .search input.watermarked {
    color: #636363;
}

body,
h1,
h1 span,
h2,
h3,
h4,
h5,
p,
label,
input,
optgroup,
select,
textarea,
.Basket h1,
.weWrap,
.Basket #BasketDiv table td,
.Basket #BasketDiv .basketsubtotal,
.sizeText,
.Profile h3,
.DontForgetText,
#productDetails #infoTabs .infoTabPage,
#productDetails #infoTabs .infoTabPage a,
#productDetails #infoTabs .infoTabPage h1,
#productDetails #infoTabs .infoTabPage h2,
#productDetails #infoTabs .infoTabPage h3,
#productImages .imageControls .zoomControl:hover,
#productImages .imageControls .spinControl:hover,
#productDetails #infoTabs .infoTabPage p,
.sizegroup h1,
.csHomeTable li.header a,
ins,
mark,
nav#topMenu > ul li.ClearBack > a,
nav#topMenu > ul li.ClearBack > a:hover,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .sdmColSaleFooter > a,
.PreDate,
.productcolour,
.productsize,
.categorycopyd4,
.MobFiltersText,
.sizeslink,
.ui-autocomplete > li > .ui-corner-all,
.MobSearchDropDown > li > a {
    color: var(--site-primary);
}

.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a,
.entryPaging a {
    color: #333 !important;
}

#pagernav a,
.dbc,
#TooManyDailyOfferItemsError,
.jquery_tools_tooltip,
.ui-autocomplete li #ui-active-menuitem,
.sg_colHead,
.entryPaging a:hover,
#divBag.active .HeaderCheckoutLink {
    color: #fff;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.entryPaging a:hover {
    color: var(--site-primary) !important;
}

.dbc,
.contentwrap,
.header,
.productFilter-slider,
#topLinkMenu ul li a:hover {
    background-color: transparent;
}

div.NewMenuWrap {
    background: rgba(255, 255, 255, 0.95);
}

nav#topMenu > ul li.SaleTop,
#TooManyDailyOfferItemsError,
nav#topMenu > ul li.SaleTop:hover {
    background-color: #ed0000;
}

input[type="submit"]:hover {
    background-color: var(--site-secondary-darker);
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
.primary2 input,
.piActiveDot,
.sizeHeader,
.cs_sizeguide > tbody > tr.sg_colHead,
.mp-level,
.sub-buttons input[type="button"]:hover,
.watchshop-plp #mobFilterControls li .textIconWrap {
    background-color: var(--site-button-primary-background) !important;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction {
    background-color: var(--site-secondary);
}

.ImgButWrap a:hover,
.ImgButWrap input:hover,
.ImgButWrap a:focus,
a.prc_commentpostingpanelcommentsectionlink:hover,
.Basket #BasketDiv .UpdateQuant:hover,
.Login .loginContainer .ImgButWrap:hover,
.Profile a.dnnPrimaryAction:hover,
#addressbook-edit-dialog a.dnnPrimaryAction:hover,
button:hover,
input[type="button"]:hover,
.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
.buttonAction:hover,
.basketLink .langlogin a.login:hover,
.s-basket-button-right input:hover,
.watchshop-plp #mobFilterControls li .textIconWrap:hover {
    background-color: var(--site-button-primary-background-hover) !important;
    color: var(--site-white) !important;
}

.OrderGroup .OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a {
    background-color: var(--site-button-secondary-background) !important;
    color: var(--site-button-secondary-color) !important;
    text-transform: capitalize;
}

.OrderGroup .OrderColC .ButtonOrder a:hover,
.OrderColC .TrackOrder a:hover {
    background-color: var(--site-button-secondary-background-hover) !important;
}

#addressbook-delete-save {
    color: var(--site-white) !important;
}

.primary2 a.dnnPrimaryAction:hover,
.primary2 input:hover,
#aViewBag:hover,
.CheckWrap a.ContinueOn:hover,
.CheckWrap .ContinueOn a:hover,
.CheckWrap input.ContinueOn:hover,
.CareersBrand .vacancy-search-submit a:hover,
.CareersBrand .vacancy-more-info-btn a:hover,
.BasketNew a.ContinueOn:hover,
#divBag.active .HeaderCheckoutLink:hover,
#divBag.active .HeaderCheckoutLink:focus,
.OrderCompleteRegistration .ContinueOn:hover,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction:hover {
    background-color: var(--site-button-primary-background-hover) !important;
}

.BodyWrap,
#BodyWrap,
.DontForgetText,
body,
.ui-state-active a,
.ui-widget-content .ui-state-active a,
.ui-widget-header .ui-state-active a,
.HeaderWrap,
#OrderHistoryPeriod select:focus,
#productDetails #infoTabs .infoTabPageContainer,
#productDetails #infoTabs .multiPage,
.RadTabStripTop_Vista .rtsLevel .rtsLink,
.RadTabStripBottom_Vista .rtsLevel .rtsLink,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn,
.RadTabStrip .rtsLevel .rtsSelected .rtsIn:hover,
#divBagItems,
#divEmptyErrorMessage,
#divAjaxLoaderImage,
#ulBag li,
.ASCdelivery2 .FullAdvert img,
.FourHovers ul li > div,
#mobControlBar {
    background-color: #fff;
}

.product-line-card__description-move button .button-icon svg,
.product-line-card__description-remove button .button-icon svg {
    width: 100%;
    height: 100%;
    stroke-width: 0;
}

.product-line-card__quantity-minus:hover,
.product-line-card__quantity-plus:hover {
    background-color: var(--site-secondary-lighter) !important;
    color: var(--site-primary);
    border: 1px solid var(--site-primary);
}

.RadTabStrip .rtsLevel .rtsIn:hover {
    background-color: #f1f1f1;
}

.currentPage,
.greyBoxBrand,
.s-checkout-fix .secondary a,
.BagandCheckoutBtn .secondary2 a {
    background: #fff !important;
}

.jquery_tools_tooltip {
    background-color: #333;
}

#Storetext {
    background: rgba(51, 51, 51, 0.5);
}

.sub-buttons input[type="button"] {
    background-color: #000 !important;
}

.advert .pagination,
.Home .advert .swiper-container,
.Vieworders {
    background-color: #ccc;
}

.sizegroup h1,
.c_BlackTitle h3,
.csHomeTable li.header {
    background-color: #eee;
}

#Cancelled-popup-container,
#InProgress-popup-container,
#NewOrder-popup-container,
#Rejected-popup-container,
#Shipped-popup-container,
#Submitted-popup-container,
#OrderHistoryPeriod select {
    background-color: #f1f1f1;
}

.byPostGroup {
    background-color: #f7f7f7;
}

.entryPaging a:hover {
    background-color: #666 !important;
}

ins {
    background: #ff9;
}

mark {
    background: #ff0;
}

nav#topMenu > ul li.root.ClearBack {
    background-color: #f3d600;
}

nav#topMenu > ul li.root.ClearBack:hover {
    background-color: #e5ca00 !important;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aPayNow,
#aViewBag,
.addToBasketContainer .ImgButWrap a,
.PersVouchBasketContainer .ImgButWrap a,
.CheckWrap .ImgButWrap a,
.CheckWrap a.ContinueOn,
.CheckWrap .ContinueOn a,
.CheckWrap input.ContinueOn,
.CareersBrand .vacancy-search-submit a,
.CareersBrand .vacancy-more-info-btn a {
    background-image: none;
    border: 0;
    border-radius: 0;
    filter: none;
    text-shadow: none;
}

.groupquestions li {
    background: transparent url("/images/core/diam4vr.png") no-repeat scroll left center;
}

ul.toptabs {
    width: 100%;
    margin: auto;
}

.ui-state-default a,
.ui-widget-content .ui-state-default a,
.ui-widget-header .ui-state-default a,
.activeSection .sectionGroup h1::before {
    background-color: #bfb7b0;
}

.blkBldTxt,
.redBldTxt {
    font-weight: 700;
}

.redBldTxt {
    color: #ed0000;
}

.ImgButWrap a,
.ImgButWrap a:active,
.ImgButWrap a:visited,
.ImgButWrap a:link,
.ImgButWrap input,
a.prc_commentpostingpanelcommentsectionlink,
.Basket #BasketDiv .UpdateQuant,
.Login .loginContainer .ImgButWrap,
.Profile a.dnnPrimaryAction,
#addressbook-edit-dialog a.dnnPrimaryAction,
button,
input[type="button"],
input[type="submit"],
.primary2 a.dnnPrimaryAction,
.primary2 input,
#aCheckout,
#aViewBag,
.PersVouchBasketContainer .ImgButWrap a,
.ToplinksGroup .search #cmdSearch,
#divBag,
#divBag.active .HeaderCheckoutLink,
nav#topMenu ul li.root.brandsMenu .SubMenuWrapper ul li.level1 a,
#productDetails .personalisationTitlePanel,
.OrderComplete.Checkout22 a.button,
.Checkout22 .OrderCompleteRegistration .ContinueOn {
    transition: all 0.3s ease-in;
}

.PersonaliseSpace .PersVouchBasketContainer .ImgButWrap a {
    font-size: 1.3em;
    letter-spacing: 0.1em;
    font-weight: bold;
    padding: 20px 5px;
    text-transform: uppercase;
}
