.watchshop-pdp .AltProdDet {
    .member-price {
        justify-content: start;
        text-align: left;
        font-size: 15px;
        margin: 5px 0;

        .member-price-label,
        .member-price-value {
            text-transform: capitalize;
            font-weight: var(--font-weight-bold);
        }
    }

    .pdpPriceRating {
        padding-left: 0;
        padding-right: 0;

        .pdpPrice {
            #lblSellingPrice {
                font-size: var(--body-font-size);
                font-weight: var(--font-weight-bold);
            }
        }

        #TicketPriceDiv2,
        #lblFromText {
            font-size: var(--body-font-size);
            color: var(--site-primary);
        }
    }
}
