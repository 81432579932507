:root {
    --site-black: #000;
    --site-white: #fff;
    --site-light-grey: #e7eaec;
    --site-light-grey-e8: #e8e8e8;
    --site-lightest-grey: #fafafa;
    --site-mid-grey: #808080;
    --site-dark-grey: #3d3d3d;
    /* Brand */
    --site-primary: #000000;
    --site-secondary: #04776e;
    --site-secondary-lighter: #b6efdd;
    --site-secondary-darker: #036059;
    --site-tertiary: #ff6e34;
    --site-sale: #cc0a38;
    --site-border-radius: 0;
    --site-button-primary-background: var(--site-primary);
    --site-button-primary-background-hover: var(--site-secondary);
    --site-button-primary-color: var(--site-white);
    --site-button-primary-color-hover: var(--site-white);
    --site-button-secondary-background: var(--site-secondary);
    --site-button-secondary-background-hover: var(--site-secondary-darker);
    --site-button-secondary-color: var(--site-white);
    --site-button-secondary-color-hover: var(--site-white);
    --site-button-focus: 1px solid var(--site-primary);
    --site-button-border-radius: var(--site-border-radius);
    /* Font */
    --body-font-size: 14px;
    --body-font-size-small: 12px;
    --subtitle-font-size: 16px;
    --font-weight-regular: normal;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-black: 900;
    --default-font-family: Figtree, arial, helvetica, sans-serif;
    /* Search autocomplete spacer */
    --autocomplete-top: 4px;

    @media (min-width: 768px) {
        :root {
            --autocomplete-top: 0;
        }
    }
    /* Spacing unit i.e calc(--spacing-unit * 4) */
    --spacing-unit: 4px;
    /* Container max width that will change to larger on larger screens */
    --container-max-width: 1400px;

    @media (min-width: 1640px) {
        --container-max-width: 1600px;
    }

    --spritesheet: url("/images/core/sd-elevate-icons-v9.svg");
    /* Wishlist Variables */
    --font-family-normal: var(--default-font-family);
    --font-family-bold: var(--default-font-family);
    --font-family: var(--default-font-family);
    --font-family-italic: var(--default-font-family);
    --wishlist-background-color: var(--site-lightest-grey);
    --wishlist-action-border-color: var(--site-light-grey);
    --wishlist-popup-border-color: var(--site-light-grey);
    --wishlist-popup-header-border-color: var(--site-light-grey);
    --wishlist-description-brand-color: var(--site-primary);
    --wishlist-no-image-color: var(--site-light-grey);
    --wishlist-tick-background-color: var(--site-secondary);
    --wishlist-scroll-background-color: var(--site-black);
    --wishlist-header-font-style: normal;
    --wishlist-summary-header-font-style: normal;
    --wishlist-button-text-transform: capitalize;
    --wishlist-button-font-style: normal;
    --wishlist-button-border-radius: 0;
    /* Cart Variables */
    --product-line-item-font-family-bold: var(--default-font-family);
    --product-line-item-price-font-weight: var(--font-weight-regular);
    --product-line-item-original-price-color: var(--site-primary);
    --product-line-item-default-font-color: var(--site-primary);
    --cart-sticky-element-default-top-value: 132px;
    --cart-heading-font-style: normal;
    --cart-font-family: var(--default-font-family);
    --cart-font-family-bold: var(--default-font-family);
    --cart-font-family-italic: var(--default-font-family);
    --cart-button-font-style: normal;
    --cart-button-font-family: var(--default-font-family);
    --cart-button-font-weight: var(--font-weight-bold);
    --cart-button-background-color: var(--site-button-secondary-background);
    --cart-button-font-color: var(--site-button-secondary-color);
    --cart-button-transactional-background-color: var(--site-button-primary-background);
    --cart-button-transactional-font-color: var(--site-button-primary-color);
    --cart-button-transactional-hover-background-color: var(--site-button-primary-background-hover);
    /* --------------- */
}
