.watchshop-pdp .AltProdDet .title {
    display: flex;
    margin-bottom: 0;

    h1 {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .brandTitle {
        padding-bottom: 0;
    }

    .brandTitle .brandLink {
        font-size: var(--subtitle-font-size);
        line-height: 22px;

        span {
            color: var(--site-primary);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .prodTitle {
        font-size: var(--subtitle-font-size);
        font-weight: var(--font-weight-regular);
        color: var(--site-black);
        padding: var(--spacing-unit) 0;
    }
}
