.productNextDayDeliveryCountdownWrapper {
    font-family: var(--default-font-family);
    color: var(--site-primary);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: calc(var(--spacing-unit) * 3) 0 calc(var(--spacing-unit) * 6);
    padding-top: 0;
}

.frasers-plus-enabled {
    .productNextDayDeliveryCountdownWrapper {
        margin: 0 0 calc(var(--spacing-unit) * 4);
    }

    .frasers-plus-outer-container {
        padding: var(--spacing-unit) 0 calc(var(--spacing-unit) * 5);
    }
}

.ndd-countdown {
    background-color: var(--site-lightest-grey);
    padding: calc(var(--spacing-unit) * 3) var(--spacing-unit);
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .ndd-countdown-title {
        font-size: var(--body-font-size);
        color: var(--site-black);
        font-weight: var(--font-weight-regular);
        margin-left: var(--spacing-unit);
        line-height: 1.5em;
        margin-bottom: 0;
        width: 100%;
    }

    .ndd-countdown-description {
        color: var(--site-black);
        font-weight: var(--font-weight-regular);
        margin: 0 0 0 calc(var(--spacing-unit) * 2);
        order: 2;
    }

    .countdown-timer {
        font-size: var(--body-font-size);
        margin: 0;

        .countdown-timer-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: var(--body-font-size);
            width: 100%;
            color: var(--site-secondary);
            background-color: transparent;
            padding: 0;
        }

        .countdown-timer-wrapper .ndd-countdown-time-unit {
            font-weight: var(--font-weight-bold);
        }

        .countdown-timer-hour-container,
        .countdown-timer-minute-container,
        .countdown-timer-second-container {
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            align-items: center;
        }
    }

    .ndd-countdown-time-hour,
    .ndd-countdown-time-minute,
    .ndd-countdown-time-second {
        font-weight: var(--font-weight-bold);
        min-width: 20px;
        min-height: 19px;
        position: relative;
        text-align: right;
    }

    .ndd-counter-time-loader {
        color: var(--site-primary);
        /*stylelint-disable*/
        animation: dotFlashing 1s infinite linear alternate;
        /*stylelint-enable*/
        animation-delay: 0.5s;
        transform: translateX(-3px);
        display: inline-block;
    }
}
