#Body.Profile #BodyWrap {
    @media (min-width: 768px) {
        background-color: var(--site-white);
    }
}

.ControlWrap {
    .bagWishBlock,
    .accountBlock,
    .accountBlock .login,
    .accountBlock .AccountLink,
    .accountBlock .SignInLink {
        height: 100%;
    }

    .accountBlock .login {
        display: block;
    }

    .accountBlock .login .ico {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-image: var(--spritesheet);
    }

    .accountBlock .login .hidden-xs {
        display: none;
    }

    #topLinkMenu ul.TopLinkMenu .TopLinkDrop {
        line-height: 82px;

        &.TopLink > a {
            display: block;
            line-height: 61px;
            padding: 0;

            @media (min-width: 768px) {
                line-height: 71px;
            }

            span {
                position: relative;
                vertical-align: middle;
            }

            svg path {
                fill: var(--site-black);
            }
        }
    }
}

.SignInLink .login .userLoginContainer {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.Login {
    margin-top: calc(var(--spacing-unit) * 12.5);

    h1 {
        text-transform: uppercase;
        text-align: left;
        font-weight: var(--font-weight-bold);
        border-bottom: none;
    }

    .newlogin .myAccLogin .row {
        padding: calc(var(--spacing-unit) * 3) 0;
    }

    .newlogin .myAccLogin .logIcon {
        background-image: none;
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="22px" viewBox="0 0 24 22"><path class="cls-1" d="m20.74,7.993l-9.398,9.398c-.94.94-2.443.94-3.383,0l-4.699-4.699c-.94-.94-.94-2.443,0-3.383s2.443-.94,3.383,0l3.007,3.007,7.706-7.706c.94-.94,2.443-.94,3.383,0,.94.94.94,2.443,0,3.383Z"/></svg>');
    }

    .tcLogin {
        font-size: var(--body-font-size);
        padding-top: calc(var(--spacing-unit) * 10);
    }

    h2,
    .strongTitle,
    .tcLogin a {
        font-weight: var(--font-weight-bold);
        color: var(--site-primary);
    }

    .ImgButWrap a {
        border-radius: var(--site-border-radius);
        font-weight: var(--font-weight-bold);

        &::after {
            display: none;
        }
    }

    .ImgButWrap a span {
        font-family: var(--default-font-family);
    }

    label {
        min-height: auto;
        font-weight: var(--font-weight-regular);

        .SignupConfirm {
            align-items: start;
        }
    }

    input {
        border-radius: var(--site-border-radius);
        border: 1px solid var(--site-light-grey);
        color: var(--site-black);
    }

    select {
        border-radius: var(--site-border-radius);
        min-height: 40px;
        padding: calc(var(--spacing-unit)) calc(var(--spacing-unit) * 2.5);
        appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="10px" viewBox="0 0 16 10" fill="white" stroke="black" stroke-width="2" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M1.73535 2L8.04418 8L14.353 2"/></svg>');
        background-repeat: no-repeat;
        background-position: right 0.7rem top 50%;
        background-size: 1rem auto;
        border: 1px solid var(--site-light-grey);
        margin: calc(var(--spacing-unit) * 2.5) 0 0;

        @media (min-width: 1022px) {
            margin: 0;
        }
    }

    .innerBorder {
        background-color: transparent;
        border: 1px solid var(--site-light-grey);
        padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 3.75);
        margin: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 5);

        .dnnFormItem {
            padding: 0 0 calc(var(--spacing-unit) * 8.75);

            @media (min-width: 768px) {
                padding: 0 0 calc(var(--spacing-unit) * 5);
            }
        }

        .RegistrationButtons {
            padding: 0;
        }

        @media (min-width: 768px) {
            padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 5) 124px;
            margin: 0;

            .RegistrationButtons .submitBut {
                text-align: left;
            }
            .RegistrationButtons .cancelBut {
                margin-top: calc(var(--spacing-unit) * 10);
            }
        }
    }

    .UserNewReg .innerBorder,
    .usersManage .innerBorder {
        @media (min-width: 768px) {
            padding: calc(var(--spacing-unit) * 5);
        }
    }

    .newCustomer {
        .innerBorder {
            background-color: var(--site-white);
        }

        @media (max-width: 767px) {
            background-color: var(--site-white);
        }
    }
}

.product-line-card__description-sizes select,
select#countryReside {
    padding: calc(var(--spacing-unit)) calc(var(--spacing-unit) * 2.5);
    appearance: none;
    border: 1px solid var(--site-light-grey);
    margin: calc(var(--spacing-unit) * 2.5) 0 0;
    background-image: url('data:image/svg+xml;utf8,<svg width="16px" height="10px" viewBox="0 0 16 10" fill="white" stroke="black" stroke-width="2" stroke-linecap="square" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M1.73535 2L8.04418 8L14.353 2"/></svg>');
    background-position: right 0.7rem top 50%;
    background-repeat: no-repeat;
    background-size: 1rem;
    min-height: 40px;
    border-radius: var(--site-border-radius) !important;
}

.SignupConfirm {
    .NewsletterFree,
    em,
    a {
        font-weight: var(--font-weight-bold);
    }

    @media (min-width: 768px) {
        max-width: 380px;
    }
}

.login {
    &::before {
        width: 80%;
        background-position: -6px -202px;
    }

    &:hover::before {
        background-position: -70px -201px;
    }
}

.loginContainer .ForgotPass a.ForgotPasswordLinkButton {
    font-weight: var(--font-weight-bold);
    color: var(--site-primary);
    text-decoration: none;
    border-bottom: 1px solid var(--site-primary);
}

.loginContainer .SignLogIn2 {
    margin-bottom: calc(var(--spacing-unit) * 8.5);
}

.accountWrapper {
    @media (max-width: 767px) {
        .noPad {
            padding: 0;
        }
    }

    .accountBox,
    .logoutBox {
        border: 1px solid var(--site-light-grey);
        border-radius: var(--site-border-radius);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        background-color: #fff;
        position: relative;
        top: 50%;
        transition: all 200ms linear 0s;
        margin: calc(var(--spacing-unit) * 2.5) var(--spacing-unit);
        padding: calc(var(--spacing-unit) * 4);

        @media (min-width: 768px) {
            width: 94%;
            min-height: 325px;
            max-height: 305px;
            margin: 0 auto 32px;
            padding: 25% 40px;
        }

        &:hover {
            background-color: var(--site-primary);

            h1 {
                color: #fff;
            }

            p {
                color: #fff;
            }
        }

        h1 {
            font-weight: 700;
            font-size: var(--subtitle-font-size);
            color: var(--site-primary);
        }

        p {
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-regular);
            color: var(--site-primary);
        }

        .personalInfo,
        .changePassword,
        .addressBook,
        .orderHistory,
        .manageCards,
        .fastPay,
        .wishList,
        .contactPreferences,
        .studioPay,
        .logOut {
            height: 35px;
            width: 35px;
            margin: 0;
            display: block;

            @media (min-width: 768px) {
                margin: 0 auto calc(var(--spacing-unit) * 5);
            }
        }
    }
}

#accountMenu {
    border: 1px solid var(--site-light-grey);

    a {
        color: var(--site-black);
    }

    ul.sidebar-nav li a:hover::before,
    ul.sidebar-nav li.active a::before {
        background-color: var(--site-primary);
    }

    ul.sidebar-nav li.FirstOptionAcc a {
        font-weight: var(--font-weight-bold);
        font-size: var(--subtitle-font-size) !important;

        &:hover {
            background-color: var(--site-white);
        }
    }

    ul.sidebar-nav li.LastOptionAcc {
        border-bottom: 1px solid var(--site-light-grey);
    }

    .sidebar-nav li.active a,
    .sidebar-nav li a {
        font-weight: var(--font-weight-regular);
        font-size: var(--body-font-size) !important;
    }

    .sidebar-nav li.active a {
        font-weight: var(--font-weight-bold);
    }

    .ImgButWrap a {
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);
    }

    ul.sidebar-nav li.ImgButWrap.SignOutAcc a {
        font-size: var(--body-font-size) !important;
        background-color: var(--site-button-primary-background) !important;
        color: var(--site-button-primary-color) !important;
        height: 44px;
        font-weight: var(--font-weight-bold);
        display: flex;
        width: calc(100% - 30px);
        margin: 0 auto calc(var(--spacing-unit) * 4);
        text-transform: initial !important;
        align-items: center;
        justify-content: center;
        border-radius: var(--site-border-radius);

        &:hover {
            background-color: var(--site-button-primary-background-hover) !important;
        }

        &::before {
            display: none;
        }
    }
}

.cprefs .yesnoSelect label.selected,
.cprefs .cprefsFrequency label.selected {
    background-color: var(--site-mid-grey);
}

.Profile .Sharebutton a {
    background-color: var(--site-mid-grey);
    color: var(--site-black) !important;
    font-family: var(--default-font-family);
}

.NewCustWrap {
    .ImgButWrap {
        border-radius: var(--site-border-radius) !important;

        a {
            border-radius: var(--site-border-radius);
            color: var(--site-primary);
            height: 44px;
            color: var(--site-button-primary-color) !important;
            background-color: var(--site-button-primary-background);
            padding: 0 calc(var(--spacing-unit) * 5);
            line-height: 44px;

            span {
                font-size: var(--subtitle-font-size);
                font-weight: var(--font-weight-bold);
                text-transform: inital;
            }

            &::after {
                display: none !important;
            }
        }
    }

    @media (min-width: 768px) {
        bottom: 34px;
        left: 15px;
        max-width: 230px;
    }
}

.ProfileWrap {
    h1,
    h2 {
        font-weight: var(--font-weight-bold);
    }

    label {
        min-height: auto;
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-regular);
    }

    p,
    .cprefsIntro,
    .safeText,
    .cprefsBirthdayHint {
        font-size: var(--body-font-size);
    }

    input,
    select {
        border: 1px solid var(--site-dark-grey);
        border-radius: var(--site-border-radius);

        &:disabled {
            border: 1px solid var(--site-light-grey) !important;
        }
    }
}

.dnnFormItem input {
    max-width: none;
    display: flex;
    align-items: flex-start;

    @media (min-width: 768px) {
        max-width: 380px;
    }
}

.Profile {
    .ImgButWrap a,
    #hypEditAddress,
    #addressbook-edit-dialog a.dnnPrimaryAction,
    #btnSaveChanges {
        display: flex;
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);
        border-radius: var(--site-border-radius);
        text-transform: initial !important;
        align-items: center;
        justify-content: center;
        height: 44px;
        background-color: var(--site-button-primary-background) !important;
        color: var(--site-button-primary-color) !important;
    }

    #BasketDiv .BagandCheckoutBtn .secondary2 a {
        background-color: var(--site-mid-grey) !important;
        border: none;
    }

    #BasketDiv .BagandCheckoutBtn .secondary2 a:hover {
        background-color: var(--site-mid-grey) !important;
    }

    .accountWrapper {
        .accountBox h1,
        .logoutBox h1 {
            color: var(--site-black);
            font-weight: var(--font-weight-bold);
            padding: 0;
            line-height: 22px;

            @media (min-width: 768px) {
                width: 100%;
                margin-bottom: calc(var(--spacing-unit) * 2.5);
            }
        }

        .accountBox:hover,
        .logoutBox:hover {
            background-color: var(--site-secondary);

            h1,
            p {
                color: var(--site-white);
            }
        }

        @media (max-width: 767px) {
            .logoutBox {
                background-color: var(--site-black);
                border-radius: var(--site-border-radius);

                h1 {
                    color: var(--site-white) !important;
                }
            }
        }

        .accountBox,
        .accountBox:hover,
        .logoutBox {
            border: 1px solid var(--site-light-grey);

            @media (max-width: 768px) {
                border-left: 0 !important;
                border-right: 0 !important;
                border-bottom: 0 !important;
            }

            > div {
                background-image: none;
            }

            > span {
                background-image: none;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                padding: 0 calc(var(--spacing-unit) * 4) 0 0;

                @media (min-width: 768px) {
                    padding: 0;
                }
            }

            .global-icon svg {
                width: 20px;

                @media (min-width: 768px) {
                    width: 24px;
                }
            }

            .global-icon.global-studioPay svg {
                @media (max-width: 767px) {
                    transform: scale(2);
                }

                @media (min-width: 768px) {
                    width: 64px;
                }
            }
        }
    }

    .s-profile-fix fieldset,
    .ProfileWrap fieldset,
    .Addresscontainer.ProfileWrap,
    .ProfileWrap.cprefs {
        padding-bottom: calc(var(--spacing-unit) * 5);
    }

    .studioPayTile {
        .global-icon.global-studioPay svg .background_card {
            fill: transparent;
        }
    }

    .ProfileWrap fieldset,
    .s-profile-fix fieldset {
        @media (max-width: 768px) {
            padding: 0 calc(var(--spacing-unit) * 5);
        }
    }
}

.modal-header .text-center .header-text {
    font-weight: var(--font-weight-bold);
    font-size: var(--subtitle-font-size);
}

#addressForm {
    label {
        font-weight: var(--font-weight-bold);
        font-size: var(--body-font-size);
        min-height: auto;
    }

    input {
        border-radius: var(--site-border-radius);
        border: 1px solid var(--site-dark-grey);
    }
}

#postcodeSearch {
    input {
        border-radius: var(--site-border-radius);
        border: 1px solid var(--site-dark-grey);
        height: 40px;
    }

    a {
        height: 40px;
        font-weight: var(--font-weight-bold);
        font-size: var(--body-font-size);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#AddressMapSplashPageCollectPlusTextContainer p {
    font-size: var(--body-font-size);
    line-height: 1.3em;
}

.threeBox {
    h2::before {
        display: none;
    }

    .innerPref {
        border-top: 1px solid var(--site-light-grey);
    }
}

.OrderHistorySummaryRange {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--spacing-unit) * 2.5);

    li {
        border: 1px solid var(--site-primary);
        border-radius: var(--site-border-radius);

        @media (min-width: 768px) {
            width: calc(33.3333% - 10px);
        }

        a,
        .selected a,
        .NoOrdersFound {
            color: var(--site-primary);
            &:hover {
                font-weight: var(--font-weight-bold);
            }
        }
    }
}

.cprefsBox ul li label {
    font-weight: var(--font-weight-bold);
    color: var(--site-primary);
}

.cprefsBox ul li label.selected,
.cprefsBox ul li label.optOut.selected,
.cprefsPopupBtns button.cprefsRemoveMe,
#btnSaveChanges {
    background-color: var(--site-button-primary-background) !important;
    color: var(--site-button-primary-color) !important;

    &:hover {
        background-color: var(--site-button-primary-background-hover) !important;
        color: var(--site-button-primary-color-hover) !important;
    }
}

.prefSelect input[type="checkbox"]:enabled:checked {
    accent-color: var(--site-secondary);
}

.Profile .s-profile-fix fieldset {
    border: 1px solid var(--site-light-grey);
}

.topMsgs .cprefsSuccessfullyUpdated {
    padding: calc(var(--spacing-unit) * 2.5) 0;
    background-color: var(--site-secondary);
    margin: 0 0 calc(var(--spacing-unit) * 2.5);
    border-radius: (--spacing-unit);
}

#pstrength-container {
    display: flex;
    align-items: center;
    max-width: 380px;
    flex-wrap: wrap;

    .pstrength-bar {
        order: 1;
    }
    .pstrength-info {
        order: 2;
        width: 100%;
        justify-content: flex-end;
        display: flex;
        padding: 0;
    }
}

#accountHeader {
    @media (max-width: 768px) {
        justify-content: flex-start;

        > div {
            width: 100%;
        }
    }

    > div,
    ul.mobileAccount {
        border: 1px solid var(--site-primary);
        border-radius: var(--site-border-radius);

        li a {
            color: var(--site-primary);
        }
    }
}

.OrderColC .ButtonOrder a,
.OrderColC .TrackOrder a {
    text-transform: uppercase;
    color: var(--site-black);
    border-radius: var(--site-border-radius);
}

.TopLinkMenu {
    display: flex;
    align-items: center;
    justify-content: center;
}

#topLinkMenu {
    width: auto;

    ul li {
        text-align: left;
    }

    &:hover .TopLinkDrop .TopSubLinkMenu {
        max-height: 600px;
    }

    &.disable-transitions .TopSubLinkMenu {
        display: none;
        transition: none;
    }

    ul li.TopLinkDrop ul {
        width: 400px;
        display: none;
        padding: 0;
        right: 0;
        position: absolute;
        top: 71px;
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 0.1);
    }

    ul li.TopLinkDrop ul::before {
        content: "";
        display: none;
        position: absolute;
        width: 24px;
        height: 13px;
        top: 0;
        right: 18px;
        border-bottom: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
    }

    ul li.TopLinkDrop ul:hover::before,
    ul li.TopLinkDrop a:hover + ul::before {
        display: block;
    }

    ul li.TopLinkDrop ul li,
    ul li.TopLinkDrop ul.TopSubLinkMenu li a {
        width: 100%;
        white-space: nowrap;
    }

    ul .TopLinkDrop {
        z-index: 80;
    }

    ul li.TopLinkDrop ul li {
        background-color: var(--site-white) !important;
        border-bottom: 1px solid var(--site-light-grey);
        border-left: 1px solid var(--site-light-grey);
        border-right: 1px solid var(--site-light-grey);
        line-height: 2.5em;
    }

    ul li.TopLinkDrop ul > li a .logoutTxt::after {
        content: "";
        display: block;
        height: calc(var(--spacing-unit) * 6);
        width: calc(var(--spacing-unit) * 6);
        background-size: 600px;
        background-position: -310px -376px;
        float: right;
    }

    ul li.TopLinkDrop ul li.FirstTopSub {
        border-top: 1px solid var(--site-light-grey);
    }

    ul li.TopLinkDrop ul li.AccOrderIcon {
        border-bottom: 1px solid var(--site-light-grey);
    }

    ul li.TopLinkDrop ul li.SignOut {
        text-align: center;

        a {
            display: inline-block;
            padding: calc(var(--spacing-unit) * 4);
            text-align: left;
            background-color: var(--site-button-secondary-background);

            &:hover {
                background-color: var(--site-button-primary-background-hover);

                span {
                    color: var(--site-button-secondary-color-hover);
                }
            }

            span {
                color: var(--site-button-secondary-color);
                font-family: var(--default-font-family);
                text-align: left;
            }
        }
    }

    ul.TopLinkMenu > li > a {
        font-size: 1em;
        line-height: 1em;
    }

    ul.TopLinkMenu > li > a span.logoutTxt {
        display: none;
        height: auto;
        margin-top: var(--spacing-unit);
        color: transparent;
    }

    ul.TopLinkMenu > li {
        width: auto;
        line-height: 65px;
        border: 0;
        position: relative;
        flex: 1 1 auto;
    }

    ul li.TopLink a {
        padding: 0;
        background-image: none;
        background-position: initial;
        background-repeat: no-repeat;
        height: 100%;
        display: block;
        color: var(--site-dark-grey);
    }

    ul li.TopLink > a {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 0;
    }

    .TopLinkMenu .TopLink a .loggedoutTxt {
        display: none;
    }

    .TopSubLinkMenu .user-name {
        font-size: var(--subtitle-font-size);
    }

    .TopSubLinkMenu .user-name a {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    ul.TopLinkMenu .iconsStores a span,
    ul.TopLinkMenu .iconsCS a span {
        display: block;
        font-size: var(--subtitle-font-size);
        color: var(--site-primary);
        font-weight: var(--font-weight-bold);
    }

    ul.TopLinkMenu .iconsCS {
        @media (max-width: 767px) {
            display: none;
        }
    }

    ul.TopLinkMenu > li span {
        color: var(--site-primary);
        font-size: var(--body-font-size);
        display: inline-block;
        line-height: 1.4em;
    }

    ul.TopLinkMenu .TopSubLinkMenu > li span {
        width: 100% !important;
        vertical-align: middle;
        line-height: 1.5em;
    }

    ul li.TopLink > a > .ico {
        height: 60px;
        background-size: 800px 800px;
        background-position: -50px -414px;
        background-repeat: no-repeat;
        width: 60px;
        display: inline-block;
        vertical-align: middle;
    }

    .TopSubLinkMenu .ico {
        display: none;
    }

    .TopLinkMenu .TopSubLinkMenu li a {
        padding: calc(var(--spacing-unit) * 4);
        background: none;
        background-color: var(--site-white);
        transition: background-color 0.3s ease-in;
    }

    .TopLinkMenu .TopSubLinkMenu li a:hover {
        background-color: var(--site-lightest-grey);
    }
}

.OrderGroup .OrderColC .ButtonOrder a,
.OrderGroup .OrderColC .TrackOrder a {
    color: var(--site-button-primary-color) !important;
    text-transform: capitalize !important;
}

.Login .submitWrap .ImgButWrap a span,
.RegistrationSubmit span,
.Login .passButtons .ImgButWrap #PasswordResetSubmit span {
    color: var(--site-button-primary-color);
}

.Login .innerBorder,
.Login input,
.Login .innerPass {
    border: 1px solid #999;
}
