.SzQuantGroup {
    margin: calc(var(--spacing-unit) * 4.5) 0;

    .sizeDropdownWrap {
        display: none;
    }

    .sizeButtons li {
        padding: var(--spacing-unit);
        margin: 0;
        width: 33.333333%;
        height: auto;
        background-color: transparent;
        font-size: var(--body-font-size);

        a {
            border: 1px solid var(--site-mid-grey);
            height: 40px;
            line-height: 40px;
            color: var(--site-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: var(--site-button-border-radius);

            &:hover,
            &:focus {
                border: 1px solid var(--site-secondary);
                background: var(--site-white);
            }
        }
    }
}

.s-productextras-column-1 span,
.s-productextras-column-1 label,
.s-productextras-checkbox-column-1 label {
    font-weight: var(--font-weight-regular);
    letter-spacing: normal;
}

@media (min-width: 1022px) {
    /* three sizes */
    .SzQuantGroup .sizeButtons li:first-child:nth-last-child(3),
    .SzQuantGroup .sizeButtons li:first-child:nth-last-child(3) ~ li {
        width: 33.3333%;
    }
}

.SzQuantGroup .sizeButtons li:first-child:nth-last-child(2),
.SzQuantGroup .sizeButtons li:first-child:nth-last-child(2) ~ li {
    width: 50%;
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight::before {
    display: none;
}

.SzQuantGroup .sizeButtons li.sizeVariantHighlight a {
    border: 1px solid var(--site-secondary);
    outline: 1px solid var(--site-secondary) !important;
    outline-offset: -2px;
    color: var(--site-primary);
    font-weight: var(--font-weight-regular);
}

.SzQuantGroup .sizeButtons li a span {
    line-height: 1em;
    pointer-events: none;
}

.SzQuantGroup .sizeButtons li.greyOut a,
.SzQuantGroup .sizeButtons li.greyOut a:hover,
.SzQuantGroup .sizeButtons li.greyOut a:focus {
    background: var(--site-light-grey-e8);
    color: var(--site-dark-grey);
    border: 1px solid var(--site-mid-grey) !important;
}

#BuySizeText {
    font-size: var(--body-font-size);
}

.swapSize .s-productextras-column-2 {
    padding-top: 0;
}

#divPopupSizeGuide {
    display: none;

    .sizeslink {
        display: flex;

        .SizeGuideText {
            color: var(--site-primary);
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-bold);
            text-decoration: none;

            &:hover {
                border-bottom: 2px solid var(--site-primary);
            }
        }
    }
}
