.ToplinksGroup {
    .container-fluid .header-overlay.overlay.activeOverlay {
        z-index: 3;
    }

    .search {
        padding: calc(var(--spacing-unit) * 1.25) calc(var(--spacing-unit) * 4);

        @media (min-width: 768px) {
            padding: calc(var(--spacing-unit) * 3.625) calc(var(--spacing-unit) * 1.25);
            width: 48%;
        }

        @media (min-width: 1366px) {
            width: 40% !important;
        }

        .dvSearch {
            z-index: 100;
            transition: background-color 0.25s linear 0s;
            height: 100%;
            border-radius: var(--site-border-radius);

            @media (max-width: 767px) {
                .search-clear-text-button {
                    top: 42%;
                }
            }
        }

        #cmdSearch {
            width: 38px;
            height: 42px;
            line-height: 45px;
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;

            span {
                width: 30px;
                height: 30px;
                background-image: var(--spritesheet);
                display: inline-block;
                font-size: 12px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-size: 800px 800px;
                background-position: -313px -381px;
            }
        }

        #txtSearch {
            outline: 1px solid var(--site-dark-grey) !important;
            border: none;
            border-radius: var(--site-border-radius);
            position: relative;
            width: 100%;
            height: 42px;
            transition: none;
            padding: calc(var(--spacing-unit) * 1.25) calc(var(--spacing-unit) * 1.25) calc(var(--spacing-unit) * 1.25)
                calc(var(--spacing-unit) * 9.5);
            background-color: var(--site-white);
            color: var(--site-primary);
            caret-color: var(--site-primary);
        }

        #txtSearch::placeholder {
            color: var(--site-dark-grey) !important;
            opacity: 1;
            filter: none;
        }
    }

    .search.activeHover .dvSearch {
        background-color: var(--site-white);
    }

    .search #txtSearch:focus,
    .search.activeHover #txtSearch {
        background-color: var(--site-white);
        padding-right: calc(var(--spacing-unit) * 11.75);
        outline: 1px solid var(--site-primary) !important;
        border: none;
        color: var(--site-primary);
        border-radius: var(--site-border-radius);

        &::placeholder {
            color: var(--site-primary) !important;
        }
    }

    #mobSearchContainer {
        display: none;
        background-color: var(--site-white);
        width: 60px;
        height: 60px;
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;

        #mobileSearchTriggerBtn {
            background: none;
            text-indent: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            svg {
                width: 24px;
                height: 24px;
                margin-top: 7px;

                path {
                    stroke-width: 1px;
                }
            }
        }
    }
}

.dvSearch .TextBoxClear {
    top: 0;
    right: 0;
    height: 44px;
    text-align: center;
    width: 38px;
    line-height: 44px;
    border: none;
    box-shadow: none;
    font-size: 11px;
    font-family: inherit;
    transform: none;

    &::before {
        content: "";
        display: inline-block;
        width: 30px;
        font-size: 11px;
        vertical-align: middle;
        height: 30px;
        background-size: 800px 800px;
        background-position: -478px -381px;
    }
}

.searchFocus .ControlWrap {
    display: none;

    @media (min-width: 768px) {
        display: block;
    }
}

.searchFocus #mobMenuContainer {
    display: none !important;
}

.ui-autocomplete {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    background: var(--site-white) !important;
    box-shadow: none;
    padding: calc(var(--spacing-unit) * 1.25);
    border-radius: var(--site-border-radius) !important;
    z-index: 6100 !important;

    .header,
    li,
    a {
        color: var(--site-primary);
    }

    @media (min-width: 1022px) {
        outline: 1px solid var(--site-primary) !important;
    }

    @media (min-width: 768px) and (max-width: 1021px) {
        border: 1px solid #aaa !important;
        width: calc(48% - 5px) !important;
        left: 50% !important;
        transform: translateX(-50%);
    }

    @media (max-width: 767px) {
        border: none !important;
        width: 100% !important;
        left: 0 !important;
    }

    @media (max-width: 767px) {
        z-index: 10071 !important;
        max-height: calc(50vh - 115px);

        > li > .ui-corner-all {
            padding-left: calc(var(--spacing-unit) * 5);
        }
    }
}

@media (max-width: 767px) {
    .searchFocus .ToplinksGroup #mobSearchContainer {
        display: block;
        z-index: 1000;
    }

    #searchSuggestionsDropdown[data-visible="yes"] {
        border-radius: 5px;
        top: calc(var(--top) + -5px);
        left: 0;
        max-height: calc(100vh - var(--top) + 5px);
    }
}

@media (max-width: 767px) {
    .searchFocus .ToplinksGroup .container-fluid > .row > .search,
    .body-menu-open .ToplinksGroup .container-fluid > .row > .search {
        z-index: 105;
    }

    .search-dropdown__innercontainer .search-suggestions-dropdown__close {
        display: none;
    }
}

#searchSuggestionsDropdown {
    .search-dropdown__wrapper h3 {
        font-size: var(--subtitle-font-size);
    }

    .search-suggestions-dropdown__section--links ul li a {
        text-transform: capitalize;
        color: var(--site-primary);
    }

    .search-suggestions-dropdown__section--product__brand,
    .search-suggestions-dropdown__section--product__brand:hover,
    .search-suggestions-dropdown__section--product__discount-ticket-price,
    .search-suggestions-dropdown__section--product__discount-ticket-price:hover,
    .search-suggestions-dropdown__section--product__price,
    .search-suggestions-dropdown__section--product__price:hover {
        font-size: var(--body-font-size);
        color: var(--site-primary);
    }

    .search-suggestions-dropdown__section--product__discount-selling-price {
        font-size: var(--body-font-size);
        color: var(--site-sale);
    }
}

@media (min-width: 768px) {
    .search-dropdown__wrapper {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        align-items: flex-start;
        margin: 0 auto;
        gap: 5rem;
        padding: 20px 60px 0;
    }
}

@media (max-width: 1021px) {
    .search-dropdown__innercontainer .search-suggestions-dropdown__close {
        display: flex;
    }
}
