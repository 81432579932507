.addToBasketContainer {
    .ImgButWrap {
        .addToBag {
            font-family: var(--default-font-family);
            font-weight: normal;
        }
    }
}

.AltProdDet {
    #productDetails {
        .addtoBagWrap {
            .addToBasketContainer {
                a {
                    background-color: var(--site-primary) !important;
                    color: var(--site-button-primary-color) !important;
                    border-radius: var(--site-button-border-radius);
                    padding: 15px;
                    position: relative;
                    margin: 0;
                    height: 44px;

                    &:hover {
                        background-color: var(--site-primary) !important;
                        color: var(--site-button-secondary-color-hover) !important;
                    }
                }

                &.sizeSelected a {
                    background-color: var(--site-button-secondary-background) !important;
                    color: var(--site-button-primary-color) !important;
                    opacity: 1;

                    &:hover {
                        background-color: var(--site-secondary-darker) !important;
                        color: var(--site-button-primary-color) !important;
                    }
                }

                span {
                    font-family: var(--default-font-family);
                    font-weight: var(--font-weight-bold);
                    font-size: var(--subtitle-font-size);
                }
            }

            .addToBagInProgress {
                background-color: var(--site-button-secondary-background) !important;

                a {
                    &::after {
                        background-color: var(--site-secondary-darker) !important;
                        right: 25%;
                    }

                    &:hover {
                        background-color: var(--site-button-secondary-background) !important;
                    }
                }
            }

            .addedToBag {
                a::after {
                    right: 0;
                    border-radius: var(--site-button-border-radius);
                    opacity: 0;
                    background-color: var(--site-button-secondary-background);
                }
            }
        }

        .PersVouchBasketContainer {
            a {
                background-color: var(--site-button-primary-background) !important;
            }

            &.sizeSelected a {
                font-family: var(--default-font-family);
                background-color: var(--site-button-primary-background) !important;

                span {
                    color: var(--site-button-primary-color);
                    font-weight: var(--font-weight-bold);
                }

                &:hover {
                    background-color: var(--site-button-primary-background-hover) !important;

                    span {
                        color: var(--site-button-primary-color-hover);
                    }
                }
            }
        }

        .personalisationContainer {
            #pnlPersonalisation {
                margin: 24px 0;

                .personalisationTitlePanel {
                    background-color: var(--site-primary);
                }
            }

            .personalisationActive,
            .pnlMyIdPersonalisation {
                .divPersAddToBasketWishlistWrapper {
                    display: flex;
                    flex-wrap: wrap;

                    #spanAddToBagWrapper {
                        a {
                            margin: 24px 0;
                            padding: calc(var(--spacing-unit) * 3.75);
                            line-height: 1.25em;
                            font-size: var(--subtitle-font-size);
                            font-weight: var(--font-weight-regular);
                        }
                    }

                    #divPersaddToBasketContainer .ImgButWrap a {
                        position: relative;
                    }

                    #divPersAddToWishListContainer {
                        .sAddToWishListWrapper a {
                            display: flex;
                            align-items: center;
                        }
                        .sAddToWishListWrapper a .global-icon-wishlist svg path {
                            stroke: var(--site-mid-grey);
                        }
                    }
                }
            }
        }

        #divPersaddToBasketContainer,
        #pnlMyIdPersonalisation .addToBasketContainer {
            flex-basis: calc(100% - 43px);
        }

        .NonBuyableOverlay {
            left: 0;
            right: 0;
        }
    }
}

.sticky-atb .sticky-atb--buysection {
    position: relative;

    .sizeSelected a {
        background-color: var(--site-button-secondary-background) !important;

        &.addToBag {
            background-color: var(--site-button-secondary-background) !important;
            color: var(--site-white) !important;
            padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 4);
            border-radius: var(--site-button-border-radius);
            position: relative;
            margin: 0;
            text-transform: capitalize;
            letter-spacing: 0;

            &:hover {
                background-color: var(--site-secondary-darker) !important;
                color: var(--site-button-primary-color-hover) !important;
            }
        }
    }

    a.addToBag {
        background-color: var(--site-button-primary-background) !important;
        color: var(--site-white) !important;
        padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 4);
        border-radius: var(--site-button-border-radius);
        font-weight: var(--font-weight-bold);
        position: relative;
        margin: 0;
        transition: all 250ms linear 0s;

        &:hover {
            background-color: var(--site-button-primary-background-hover) !important;
            color: var(--site-button-primary-color-hover) !important;
        }
    }

    .NonBuyableOverlayMessage {
        height: 100%;
        font-size: 12px;
        padding: 0;
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.sAddToWishListWrapper {
    #aAddToWishList {
        display: flex;
        align-items: center;
    }

    .wishListSVG svg path {
        stroke: var(--site-black);

        &:hover {
            fill: var(--site-black);
        }
    }

    .global-icon-wishlist svg path {
        stroke: var(--site-black);
    }

    .global-icon-wishlist svg:hover {
        fill: var(--site-black);

        svg path {
            fill: var(--site-black);
        }
    }

    .addedWishList svg path {
        fill: var(--site-black);
    }
}

.WishListContain a:hover svg path {
    stroke: var(--site-black);
}

#sticky-atb-wrapper.stickyCTA-active {
    transition: top 0.3s ease-in-out;
    top: 121px;

    @media (min-width: 768px) {
        top: 121px;
    }

    .menu-search-shown & {
        top: 120px;

        @media (min-width: 768px) {
            top: 121px;
        }

        @media (min-width: 1022px) {
            top: 121px;
        }
    }
}

.AltProdDet #productDetails .PersVouchBasketContainer.addToBagInProgress a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addToBagInProgress a::after,
.sticky-atb .sticky-atb--buysection .addToBagInProgress a::after {
    background-color: var(--site-secondary-darker);
    right: 25%;
}

.AltProdDet #productDetails .PersVouchBasketContainer.addedToBag a::after,
#productMyIdPerosonalisationPages .addtoBagWrap.addedToBag a::after,
.sticky-atb .sticky-atb--buysection .addedToBag a::after {
    right: 0;
    border-radius: var(--site-button-border-radius);
    opacity: 0;
    background-color: var(--site-secondary-darke);
}

.AltProdDet .productVariantContainer .personalisationActive {
    margin-top: calc(var(--spacing-unit) * 5);
    margin-bottom: var(--spacing-unit);
}

#PreorderPopupModal {
    .addToBasketContainer {
        a {
            background-color: var(--site-secondary) !important;
            color: var(--site-primary) !important;
            border-radius: var(--site-button-border-radius);
            padding: 15px;
            position: relative;
            margin: 0;
            text-transform: uppercase;

            &:hover {
                background-color: var(--site-button-secondary-background-hover) !important;
                color: var(--site-button-secondary-color-hover) !important;
            }

            &.preorderCancelButton {
                background-color: var(--site-button-secondary-background) !important;
                color: var(--site-button-secondary-color) !important;

                &:hover {
                    background-color: var(--site-button-secondary-background-hover) !important;
                    color: var(--site-button-secondary-color-hover) !important;
                }
            }

            span {
                font-family: var(--default-font-family);
                font-weight: var(--font-weight-bold);
                font-size: var(--subtitle-font-size);
            }
        }
    }
}

#ageVerificationPopup {
    .modal-body {
        a.ageVerificationOk {
            background-color: var(--site-secondary) !important;
            color: var(--site-primary) !important;
            border-radius: var(--site-button-border-radius);
            padding: 15px;
            position: relative;
            margin: 0;
            text-transform: uppercase;
            font-family: var(--default-font-family);
            font-weight: var(--font-weight-bold);
            font-size: var(--subtitle-font-size);
            border: none;

            &:hover {
                background-color: var(--site-button-secondary-background-hover) !important;
                color: var(--site-button-secondary-color-hover) !important;
            }
        }
    }
}
