.mobile-sort-and-filter-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: center;
    justify-content: flex-end;
    padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 4);
    gap: calc(var(--spacing-unit) * 4);
    position: sticky;
    z-index: 1;
    background: var(--site-white);
    top: 120px;
    transition: top 0.3s ease-in-out;
    z-index: 10;

    @media (min-width: 1022px) {
        display: none;
        padding: 0;
        background: none;
        border: none;
    }

    .sticky-header-applied & {
        top: 120px;

        @media (min-width: 768px) and (max-width: 1021px) {
            top: 115px;
        }
    }

    .mobile-sort-container,
    .mobile-filter-container {
        flex: 1;
    }

    .mobile-sort-container {
        position: relative;
    }

    .mobile-filter-button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        width: 100%;
        height: 43px;
        background: none;
        border: 1px solid var(--site-black);

        .mobile-filter-button__text {
            font-weight: var(--font-weight-regular);
            font-size: var(--subtitle-font-size);
            color: var(--site-black);
            padding: 0;
        }

        .global-icon-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            width: 20px;
            background-size: 870px 600px;
            background-position: -426px -321px;
            margin-right: 10px;

            svg {
                display: none;
            }
        }
    }

    .MobSortSelector {
        margin: 0;
        height: 100%;
        padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 3.5);

        .FilterCollapseImage {
            order: 1;
            margin: 0 calc(var(--spacing-unit) * 2.5) 0 0;
            height: 20px;
            width: 20px;
        }
    }

    .mobile-filter-button,
    .MobSortSelector {
        border: 1px solid var(--site-primary);
        background-color: var(--site-white) !important;
        border-radius: 4px;
        transition: border-color 0.25s linear 0s;
        padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 3.5);
    }

    .MobSortSelector .productFilterTitleBox,
    .MobSortSelector .productFilterTitleBox.FilterClosed {
        padding: 0;
    }

    .MobSortSelector:has(.productFilterTitleBox:not(.FilterClosed)) {
        border-color: var(--site-black);
        border-radius: 4px 4px 0 0;
        border-bottom-color: var(--site-light-grey);
    }

    .mobTitlArro {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        height: 100%;
        left: auto;
    }

    .mobSortDesc {
        width: auto;
        order: 2;
    }

    .productFilterTitle,
    .MobFiltersText {
        font-weight: var(--font-weight-regular);
        font-size: var(--subtitle-font-size);
        color: var(--site-primary);
        padding: 0;
        letter-spacing: 0;
    }

    .MobFiltersText {
        display: flex;

        &:before {
            content: "";
            display: block;
            content: "";
            display: block;
            height: 20px;
            width: 20px;
            background-size: 870px 600px;
            background-position: -428px -323px;
            margin-right: 10px;
        }
    }

    .MobSortSelector > .productFilterList {
        position: absolute;
        z-index: 960;
        background-color: var(--site-white);
        border: 1px solid var(--site-black);
        border-top: none;
        border-radius: 0 0 var(--site-border-radius) var(--site-border-radius);
        padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 4);
        left: auto;
        top: 42px;
        width: 100%;
        margin-left: -15px;

        .FilterAnchor {
            padding: calc(var(--spacing-unit) * 2.5) 0;
        }

        .SelectableFilter {
            display: flex;
            align-items: center;

            &::before {
                content: "";
                margin-right: calc(var(--spacing-unit) * 2.5);
                border-radius: 50%;
            }

            &.SelectedFilter::before {
                background: var(--site-black);
            }
        }
    }

    .productFilterTitleBox {
        @media (max-width: 1021px) {
            background-color: transparent;
        }

        .glyphicon::before {
            content: "";
            display: block;
            height: 20px;
            width: 20px;
            background-size: 870px 600px;
            background-position: -491px -285px;
            transform: rotate(180deg);
        }

        &.FilterClosed .glyphicon::before {
            transform: rotate(0deg);
        }
    }
}

.Browse .productFilterList .SelectedFilter::before {
    background: var(--site-black);
}

@media (max-width: 1022px) {
    #mobFilterControls .textIconWrap .glyphicon {
        display: none !important;
    }
}
