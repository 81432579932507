@media (min-width: 1022px) {
    #productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a {
        flex: 0 0 calc(50% - 5px);
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayOne .innerImageContainer {
        justify-content: center;
    }

    #productImages #productImageContainer .productImage.productImageGrid .gridDisplayOne .innerImageContainer a {
        flex: 0 0 calc(68% - 5px);
    }
}
