#pnlRecentlyViewedProducts .AltStratDescription,
#hotspotModal .hsColourDesc .ColourDesc,
#hotspotModal .hsColourDesc .ColourLabel,
#hotspotModal .sizeGuideLink a,
#hotspotModal .hsSizeLabel,
.watchshop-plp .s-productthumbbox .s-producttext-top-wrapper a .nameWrapTitle,
#lblCategoryCopy .catTemplate .siblingLinks a,
.topheadbox .siblingLinks a,
#lblCategoryCopy .catTemplate-FWC .siblingLinks a,
.catTemplate-FWC .siblingLinks a,
#divsiblinglistwrapper ul li a,
#pnlRecentlyViewProducts .AltStratBrand .wrap,
#pnlRecentlyViewedProducts .AltStratBrand,
.watchshop-plp #hotspotModal #hsViewProduct a,
.watchshop-plp .s-productthumbbox .s-producttext-top-wrapper a .brandWrapTitle,
.bv_text,
.watchshop-plp .Responsive .s-largered .fromProductPrice,
.watchshop-plp .s-producttext-price .s-largered,
.watchshop-plp .Responsive .RefandPrice span,
.pppt span,
.mobSortFilter .MobFiltersText,
.watchshop-plp .productFilterList .FilterValue,
.brandTitle,
#divBrandSearch input,
#Body.watchshop-plp #mobFilterControls li .textIconWrap,
.mobappfltrsNo,
.sticky-atb .originalTicket,
.ToplinksGroup .search #txtSearch,
nav#topMenu ul li.root > a,
.ui-autocomplete > li > .ui-corner-all,
#mp-menu .mp-level .menuitemtext,
.watchshop-pdp .AltProdDet .title h1 #lblProductName,
.watchshop-pdp .WishListContain a,
.watchshop-plp .dropprods_Order,
.FooterSubGroup .FooterHeader,
.FooterWrap .footerContainer .SubTitle,
.languageSelector span.countryName,
#topLinkMenu ul.TopLinkMenu > li span,
.RightBarLinks div.TopLinkMenu > a span,
.sitewideBanner .swiper-slide .swiper-text,
.languageSelectorMobile .countryName,
#liMobileCurrencySelector .currencyOption label,
.watchshop-pdp .Responsive .spnFrom,
.watchshop-pdp .AltProdDet .AdditionalPriceLabel,
.watchshop-pdp .AltProdDet .originalprice,
#MobtxtSearch,
#hotspotModal .PinWrapText select,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdTitle,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdBrand,
.cookie-banner__content,
.PSProdBrand,
.mobSortDesc .productFilterTitle,
#topLinkMenu ul.TopLinkMenu .iconsStores a span,
nav#topMenu li.root .SubMenuWrapper > ul .level1.sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper > ul .level2.sdmColHeader > a,
#topLinkMenu ul.TopLinkMenu .iconsCS a span,
.hotspotbuy.hotspotquickbuy .QuickLookText,
#hotspotModal .PinWrapText #hsViewProduct a,
#lblCategoryHeader,
#hotspotModal #hsAddToBagContainer a,
.storefinderSearchButtons input,
#divBagItemsChild .bagHeader p,
.Basket #divContinueSecurely.ImgButWrap a,
.Basket #divContinueSecurelyTop.ImgButWrap a,
.newBasketPromoCode .PromoCodeBut a {
    font-family: var(--default-font-family);
}

.reviews-container
    .bv-cv2-cleanslate
    .bv-content-pagination
    .bv-content-pagination-container
    .bv-content-btn-pages-load-more,
.currencySelector .languageSwitcherLabel,
.BaskName,
.lillBasket #lblBagSubTotal,
.lillBasket .BaskName,
.lillBasket .ColrandSize,
.lillBasket .BaskQuant,
.PSPlacementWrapper .SuggestedProduct > h4 a .PSProdName,
.SzQuantGroup .SizeGuideText,
.watchshop-pdp .s-productextras-column-1 span,
.watchshop-pdp .s-productextras-column-1 label {
    font-family: var(--default-font-family) !important;
}

.watchshop-plp .HeadnCount h1,
.stackFlexHeader h3,
.watchshop-plp .layout span.ViewbyCols,
.watchshop-plp .pagination .sortbyfilter label,
.watchshop-plp #mobFilterControls .mobApplyFilter .textIconWrap,
.watchshop-plp .s-largered .curprice,
.sticky-atb .pdpPrice span,
.watchshop-plp h1,
.watchshop-pdp .AltProdDet .pdpPrice,
.watchshop-pdp .s-productextras-column-1 span,
.watchshop-pdp .s-productextras-checkbox-column-1 label,
.watchshop-pdp .s-productextras-column-1 label,
.watchshop-pdp #parDeliveryMethods li span.TitleDel,
.watchshop-pdp .RadTabStrip .rtsLevel .rtsIn,
.infoTabPage h2,
.watchshop-pdp .AltProdDet .addToBasketContainer .ImgButWrap a,
.s-productextras-column-3 a,
.ProdDetRight .infoaccordion .panel-body h2,
.PSPlacementWrapper .PSPlacementTitle,
#pnlRecentlyViewedProducts .AltStratTitle,
.appLinks .appText,
#divCurrencySelector span.currencyHeader,
#divLanguageSelector span.languageHeader,
.signup-wrapper .signupInner .SubTitle,
li#mob-signout a,
#liMobileLanguageSelector a.mp-back span,
#liMobileCurrencySelector a.mp-back span,
.mobMenuGroup li .mp-level .menulevelheader a,
.MobileMenuContentWrap .shop > ul > li .menulevelheader > a,
.mobMenuGroup #mob-myaccount .menulevelheader a,
.lillBasket #spanBagSubTotalValue,
#topLinkMenu ul li.TopLinkDrop ul li.SignOut a span,
#mp-menu .shop > ul li.level2.sdmColHeader a,
.PSPlacementWrapper .SuggestedProduct .PSProdPrice div,
#pnlRecentlyViewedProducts .AltStratRefSell div,
.PSPlacementWrapper .SuggestedProduct .PSProdPrice span,
#pnlRecentlyViewedProducts .AltStratRefSell span,
.ui-autocomplete .header,
.infoaccordions .DeliveryMethods .price,
#DisplayAttributes li span.feature-name,
.infoaccordions .DeliveryMethods li span.TitleDel,
.ProdDetails .ShopTheLook .s-largered .curprice,
.order-details-title,
#accountHeader p,
.order-summary-total-label,
.order-summary-total,
.product-gallery .product-price,
#divWishList .wishlist-summary ul li .wishItemContentWrap .wishItemContentWrap-price,
.OrderComplete a.button,
.OrderCompleteRegistration .ContinueOn,
.OrderCompleteLogin .OrderCompleteLoginForm .dnnPrimaryAction,
nav#topMenu li.root .SubMenuWrapper li .fourPromoItem a .fourPromoCTA,
.currencyCountryHeader,
#topLinkMenu .TopSubLinkMenu .user-name a,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level1.sdmColHeader > a,
nav#topMenu ul li.root.sidebarSubMenu .SubMenuWrapper .level2.leftHeader.sdmColHeader > a,
nav#topMenu li.root .SubMenuWrapper li .fourPromoSlot.BottomPromoRight .fourPromoItem.shopAllPromo .fourPromoCTA div,
#divPersaddToBasketContainer .ImgButWrap a,
#pnlRecentlyViewedProducts .AltStratBrand,
nav#topMenu > ul li.newTag::after,
.am-menu ul li.newTag .menuitemtext::after,
nav#topMenu > ul li.saleTag::after,
.am-menu ul li.saleTag .menuitemtext::after,
nav#topMenu > ul li.hotTag::after,
.am-menu ul li.hotTag .menuitemtext::after,
nav#topMenu ul li.root .SubMenuWrapper > ul li.level1.sdmColHeader.BlackItalicFont > a,
#productImages #productImageContainer .productImage.productImageGrid .innerImageContainer a.viewMoreNumber,
.sticky-atb .sticky-atb--buysection .addToBag,
.OrderComplete h1,
.order-complete-subtitle,
.order-details-section h2,
.OrderComplete h2,
.order-details-section p b,
strong {
    font-family: var(--default-font-family);
    font-weight: var(--font-weight-bold);
}

.watchshop-pdp #productDetails .rating-container .bv_numReviews_text {
    font-family: var(--default-font-family) !important;
    font-weight: var(--font-weight-bold) !important;
}

.back-to-top,
.watchshop-pdp .pdpWishListIco,
.watchshop-pdp .SuggestedProduct .WishIcon,
.watchshop-pdp .hotspotbuy .QuickLookIcon,
.watchshop-pdp .hotspotbuy .WishIcon,
a.bx-prev,
a.bx-next,
.watchshop-pdp #mainDetails p.preOrder::before,
.watchshop-pdp .productImage .mainImageButtonPrev a span,
.watchshop-pdp .productImage .mainImageButtonNext a span,
.updateIcon,
#li_placement .SuggestedProduct .QuickLookIcon,
#li_placement .SuggestedProduct .WishIcon,
#li_placement .hotspotbuy .QuickLookIcon,
#li_placement .hotspotbuy .WishIcon,
.watchshop-pdp .SuggestedProduct .QuickLookIcon,
.watchshop-pdp .AltProdDet #piThumbs #sPrevImg span::before,
.watchshop-pdp .AltProdDet #piThumbs #sNextImg span::before,
.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.lillBasket .PreviousDisable,
.lillBasket .NextDisable,
.BasketNew .BasketQuantBut::before,
.BasketNew .BasketQuantBut.s-basket-plus-button::before,
.Responsive .BasketNew #BasketDiv .NewUpdateQuant:hover .updateIcon,
.watchshop-plp .catTemplateNew .viewMore span.OpenView::after,
.watchshop-plp .catTemplateNew .viewMore span.ClosedView::after,
.FooterHeader span,
.watchshop-plp .catTemplate .viewMore span.OpenView::after,
.watchshop-plp .catTemplate .viewMore span.ClosedView::after,
.HelpIco,
.wishlist-summary .items-nav {
    background-image: url("/images/core/sd-new-main-sprite-15-v12.svg");
}

.ControlWrap .accountBlock .login .ico,
.mobMenuGroup #mob-myaccount .mp-back::before,
#liMobileLanguageSelector a.mp-back::before,
#liMobileCurrencySelector a.mp-back::before,
.am-menu .am-level a.MobMenChevron::after,
.mp-menu .mp-level a.MobMenChevron::after,
.menu-trigger::before,
#MobcmdSearch::before,
.FooterWrap .footerContainer .footerInfoSection .footerStoreFinder a::before,
.FooterWrap .footerContainer .footerInfoSection .footerCustServ a::before,
#topLinkMenu ul.TopLinkMenu .iconsStores a::before,
#topLinkMenu ul.TopLinkMenu .iconsCS a::before,
.lillBasket #aCheckout span::after,
#topLinkMenu ul li.TopLink > a > .ico,
#topLinkMenu ul li.TopLinkDrop ul li a .logoutTxt::after,
.lillBasket a.removeClass::before,
.lillBasket a.bagMoveToWishlistClass::before,
.dvSearch .TextBoxClear::before,
.sitewideBanner .closingIcon div,
.s-basket-remove-button a .deleteItemIcon,
.FooterSubGroup .FooterHeader span,
.mobFilterAppIcon,
.selectedFilterToggle::after,
.Responsive .mobDdClose .clsFilterIcon::before,
.watchshop-plp #spnSearchIcon .glyphicon,
.productFilter .productFilterList #spnClearBrandFilter,
.PageNumber a.NextLink,
.PageNumber a.PrevLink {
    background-image: var(--spritesheet);
}

.mobile-sort-and-filter-wrapper .productFilterTitleBox .glyphicon::before,
.global-icon-filter,
.hotspotbuy.hotspotwishlist .WishIcon,
.mobile-sort-and-filter-wrapper .MobFiltersText:before {
    background-image: var(--spritesheet);
}

.Profile .ContentWrapper,
.Basket .ContentWrapper,
.Login .ContentWrapper,
.Registration .ContentWrapper,
.StoreLocator .ContentWrapper {
    max-width: var(--container-max-width);
}

.mp-container {
    overflow: unset;
}

.body-menu-open {
    overflow: visible;
}

.modal .close {
    content: "";
    display: inline-block;
    background-image: url("/images/core/shared-close-x-black.svg") !important;
    background-size: 100% !important;
    background-position: center !important;
    height: 15px;
    width: 15px;
    cursor: pointer;
    background-color: transparent !important;
    border: none;
    right: 15px;
    top: 15px;
}

span.product-sash {
    font-weight: var(--font-weight-bold);
}
