.Browse #FilterContainer {
    border: 0;
    width: 100%;

    &.MobClosed {
        display: block;
        background-color: var(--site-white);
    }

    @media (min-width: 1022px) {
        max-width: 220px;
    }

    &::before {
        display: none;
        opacity: 1;
        transition: none;
        background-color: var(--site-white);
        transition-delay: 0s;
        filter: none;
    }

    &.activeFilter::before {
        display: block;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.4);
        transition-delay: 0s;
        filter: none;
        z-index: 12;
    }

    @media (max-width: 1021px) {
        width: 100% !important;
        position: fixed;
        top: 0 !important;
        left: -100%;
        height: 100% !important;
        transition: all 250ms ease-in;
        display: block;
        visibility: hidden;
        padding-bottom: calc(var(--spacing-unit) * 20.5);

        #innerfiltercontainer {
            height: calc(100vh - 130px);
            padding: calc(var(--spacing-unit) * 2.5) calc(var(--spacing-unit) * 2.5) 0 !important;
        }

        &.filters-open {
            visibility: visible;
            transform: translateX(100%);
        }

        &.activeFilter {
            left: 0%;
        }

        &::before {
            content: "";
            position: fixed;
            z-index: 10050;
            background-color: rgba(0, 0, 0, 0.7);
            width: 0;
            height: 100%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: ease all 0.25s;
            transition-delay: 0.25s;
        }

        #mobFilterControls {
            position: absolute;
            height: auto;
            padding: calc(var(--spacing-unit) * 2.5);
            width: 100%;
            display: flex !important;
            left: 0;
            bottom: 0;
            background: var(--site-white);
        }
    }
}

.Browse #FiltersHeader {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    height: 50px;
    background-color: var(--site-white);
    z-index: 1;
    border-bottom: 1px solid var(--site-light-grey);

    @media (max-width: 1021px) {
        padding: calc(var(--spacing-unit) * 2.5) 0;
        flex-direction: column;
        height: 82px;
        align-items: start;
    }

    h2 {
        font-size: var(--body-font-size);
        margin-bottom: 0;
        font-weight: var(--font-weight-regular);
        text-transform: none;

        @media (max-width: 1021px) {
            padding: 0 calc(var(--spacing-unit) * 5);
            text-align: left;
        }

        @media (min-width: 1022px) {
            display: none;
        }
        .mobappfltrsNo.activeFilters {
            display: none;

            @media (max-width: 1021px) {
                display: inline-block;
                margin-left: 10px;
            }
        }

        .MobFiltersText {
            font-weight: var(--font-weight-bold);
            font-size: calc(var(--subtitle-font-size) + 2px);
            letter-spacing: 0;
            display: flex;
            align-items: center;
        }
    }

    .mob-filters-product-count {
        padding: calc(var(--spacing-unit) * 2) calc(var(--spacing-unit) * 5) 0;
        color: var(--site-mid -grey);

        @media (min-width: 1022px) {
            padding: 0;
        }
        .pppt {
            line-height: 1em;

            span {
                color: var(--site-mid-grey);
                font-size: var(--subtitle-font-size);
                font-weight: var(--font-weight-bold);
            }
        }
    }

    .productFiltersReset {
        margin-left: auto;

        a {
            border: 0;
            text-transform: none;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .close-mobile-filters {
        position: absolute;
        top: 18px;
        right: 20px;
        cursor: pointer;

        .global-icon svg {
            height: 18px;
            width: 18px;
        }
    }

    .FilterSelectedContainer {
        width: 100%;

        @media (max-width: 1021px) {
            margin-top: 40px;
            overflow-x: scroll;
        }

        .SelectedFiltersContainer .selectedFilters {
            display: flex;
            flex-flow: row nowrap;
            gap: 10px;
            width: max-content;

            &::before {
                content: "";
                width: 16px;
            }

            .selectedFilter a {
                padding: 15px 28px 15px 20px;

                .selectedFilterLabel {
                    font-size: var(--body-font-size);
                    font-weight: var(--font-weight-regular);
                }
            }
        }
    }
}

.Browse #innerfiltercontainer {
    @media (min-width: 1022px) {
        border: none;
        padding: 0 calc(var(--spacing-unit) * 2.5) 0 0 !important;
        flex-basis: 240px;
        position: sticky !important;
        top: 132px !important;
        height: 100%;
        width: 100% !important;
        max-height: calc(100vh - 132px) !important;
        overflow-y: auto;
        overflow-x: hidden;
    }

    @media (max-width: 1021px) {
        display: flex;
        flex-direction: column;
        padding: 0 0 71px;

        .productFilters {
            padding: 0 calc(var(--spacing-unit) * 2.5);
            margin-bottom: calc(var(--spacing-unit) * 19.5);
        }
    }

    .productFilters .productFilter {
        border: none;
        border-bottom: 1px solid var(--site-light-grey);
        padding: calc(var(--spacing-unit) * 3.75) 0;

        &.sort-by-in-filters input[type="radio"] {
            display: block;
            appearance: none;
            margin: 0;
            width: 16px;
            min-width: 16px;
            max-width: 16px;
            height: 16px;
            border: 1px solid var(--site-primary);
            background: var(--site-white);
            border-radius: var(--spacing-unit);
        }

        &.sort-by-in-filters input[type="radio"]::before {
            content: "";
            width: 10px;
            height: 10px;
            background: var(--site-black);
            margin: calc(var(--spacing-unit) * 0.5);
            display: none;
        }

        &.sort-by-in-filters input[type="radio"]:checked::before {
            display: block;
        }

        &.filtersSet .selectedFiltersGroup,
        &.FilteringApplied .selectedFiltersGroup {
            margin-top: calc(var(--spacing-unit) * 3);
        }

        .mobFiltInnerWrap {
            position: relative;
        }

        @media (min-width: 1022px) {
            &.sort-by-in-filters {
                margin: 0;
                display: block;
            }
        }

        @media (max-width: 1021px) {
            padding: 20px 0;
            margin: 0;

            .mobFiltInnerWrap {
                height: 100%;
            }
        }
    }

    h2 {
        font-size: var(--subtitle-font-size);
        font-weight: var(--font-weight-bold);
        text-transform: none;
        padding-bottom: 0;
        line-height: 20px;
        color: var(--site-primary);
    }

    .productFilterTitleBox {
        padding: 0;
        margin: 0;

        .productFilterTitle {
            font-size: var(--subtitle-font-size);
            font-weight: var(--font-weight-bold);
            text-transform: none;
            padding-bottom: 0;
            line-height: 20px;
            color: var(--site-primary);

            @media (max-width: 1021px) {
                font-weight: var(--font-weight-bold);
            }
        }

        .mobAppliedFilters {
            display: none !important;
        }

        .FilterCollapseImage {
            width: 20px;
            height: 20px;
            display: inline-block;

            &::before {
                content: "";
                display: block;
                height: 20px;
                width: 20px;
                background-image: var(--spritesheet);
                background-size: 870px 600px;
                background-position: -424px -287px;
            }
        }

        .filter-title-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .global-icon-plus {
                display: none;
            }
        }

        &.FilterClosed .filter-title-icon {
            .global-icon-minus {
                display: none;
            }

            .global-icon-plus {
                display: block;
            }
        }

        @media (max-width: 1021px) {
            height: 100%;
        }
    }

    .productFilterTitleBox.FilterClosed .FilterCollapseImage::before,
    .FilterClosed .FilterClosed .FilterCollapseImage::before {
        background-position: -386px -286px;
    }

    .selectedFiltersGroup {
        .selectedFiltersGroupInner {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;

            .filtersSet & {
                margin: 12px 0 0;
            }

            .selectedFiltersGroup.FilterClosed & {
                margin: 0;
            }

            .productFilter.FilterClosed & {
                margin: 0;
            }
        }
    }
}

.productFilterList .SelectedFilter > span {
    font-weight: var(--font-weight-regular);
}

@media (min-width: 1022px) {
    .productFilterTitleBox,
    .productFilterTitleBoxCLOSED {
        margin-bottom: 10px;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor {
        padding: 5px 5px 5px 0;
    }

    .productFilterList {
        border: none;
        padding: 0;
    }

    .productFilter .productFilterList .brandSearchSort {
        padding: 0 10px 0 0;
    }
}

@media (max-width: 1021px) {
    .Browse #mobFilterControls li {
        height: auto;
        flex: 1 1 auto;
        order: 2;
        flex-basis: 50%;
    }

    .Browse #mobFilterControls li#mobclrfltrs {
        border: 0;
        padding-right: 10px;
        order: 1;
    }

    .Browse #mobFilterControls li .textIconWrap {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 2.5);
        background-color: var(--site-button-primary-background) !important;
        border-radius: var(--site-button-border-radius);
        transition: background-color 0.25s ease-in-out;
        height: 100%;

        span {
            text-transform: capitalize;
            color: var(--site-button-primary-color);
            font-weight: var(--font-weight-bold);
            transition: color 0.25s ease-in-out;
            letter-spacing: 0;
        }

        &:hover {
            background-color: var(--site-button-primary-background-hover) !important;

            span {
                color: var(--site-button-primary-color-hover);
            }
        }
    }

    .Browse #mobFilterControls li#mobclrfltrs .textIconWrap {
        background-color: transparent !important;
        border: 1px solid var(--site-primary);
        transition:
            background-color 0.25s ease-in-out,
            border 0.25s ease-in-out;

        span {
            color: var(--site-primary);
            transition: color 0.25s ease-in-out;
        }

        &:hover {
            background-color: var(--site-button-secondary-background-hover) !important;
            border: 1px solid var(--site-secondary) !important;

            span {
                color: var(--site-button-secondary-color-hover);
            }
        }
    }

    .Browse #mobFilterControls .mobClearFilters .textIconWrap span {
        letter-spacing: normal;
    }

    .Browse #innerfiltercontainer .MobSortSelector {
        display: none;
    }
}

.Browse .productFilterList .FilterValue,
.Browse .productFilterList .FilterName {
    float: none;
    vertical-align: middle;
    display: inline;
    line-height: 18px;
    width: auto;
}

.Browse .productFilterList .FilterValue {
    font-size: var(--body-font-size-small);
    background: var(--site-light-grey-e8);
    color: var(--site-primary);
    font-weight: var(--font-weight-bold);
    padding: 4px 6px;
    border-radius: 12px;
    margin-left: 8px;

    @media (max-width: 1021px) {
        font-size: calc(var(--body-font-size) - 2px);
    }
}

.Browse .productFilterList .FilterName {
    color: var(--site-primary);
    padding-left: 12px;
    line-height: 24px;
}

.Browse .child-categories-in-filters .productFilterList .FilterName {
    padding-left: 0;
}

.Browse .productFilterList .FilterAnchor.greyOut,
.Browse .FilterListItem .FilterAnchor.greyOut:hover {
    text-decoration: none;

    .FilterName {
        color: var(--site-dark-grey);
        text-decoration: line-through;
    }
}

.Browse .productFilterList .SelectableFilter::after,
.Browse .productFilterList .SelectedFilter::after,
.Browse .productFilterList .FilterAnchor .MobSortSelectionCheckbox::before {
    content: none;
}

.pagination .sortbyfilter {
    text-align: right;
}

.Browse .pagination .layout,
.pagination .sortbyfilter {
    border: none;
}

.filtersTitleContainer {
    display: flex;
    align-items: center;
    align-content: center;
}

#FiltersHeader .mobappfltrsNo.activeFilters,
#filterByMob.activeFilters .mobappfltrsNo {
    color: var(--site-black);
    background-color: var(--site-light-grey);
    font-size: calc(var(--body-font-size) - 2px);
    font-weight: var(--font-weight-bold);
    line-height: 12px;
    letter-spacing: 0;
    padding: 5px;
    border-radius: 150px;
}

#filterByMob.activeFilters .mobappfltrsNo {
    background-color: var(--site-secondary);
    margin: 0 5px 0 auto;
}

#filterByMob .MobFiltersText .mobappfltrsNo {
    display: none;
}

#mobControlBar {
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
}

#filterByMob {
    position: static;
    text-align: left;
    top: 0;
    transform: none;
    text-transform: none;
}

.mobFilterAppIcon.glyphicon.glyphicon-ok-sign::before {
    vertical-align: top;
}

@media (max-width: 1021px) {
    .Browse .Responsive .FilteringApplied .productFilterTitleBox {
        background: transparent;
    }
}

.filtersSet .selectedFilter {
    margin-bottom: 4px;
}

.productFilterList {
    border: none;
}

.MobSortSelector .mobAppliedFilters {
    height: 0;
    display: none !important;
}

.mobSortFilter .mobAppliedFilters {
    font-size: 1em;
    height: auto;
    min-height: 15px;
}

.MobSortSelector .productFilterTitleBox.FilterClosed + .productFilterList {
    display: none;
}

.MobSortSelector .productFilterList {
    padding: 0 0 22px;
    display: block;
}

.mobSortFilter .MobSortSelector > .productFilterList .Browse .MobSortSelector input[type="radio"] {
    display: none;
}

.Browse .productFilterList .FilterAnchor .MobSortSelectionCheckbox {
    width: auto;

    @media (max-width: 1021px) {
        padding: 0;

        label {
            margin: 0;
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-regular);
        }
    }
}

.Browse .MobSortSelector label {
    font-size: 15px;
    color: var(--site-black);
}

input#MobSortOptions_discountvalue_desc {
    margin: 0;
}

.Responsive .productFilterList .FilterAnchor {
    display: flex;
    align-items: center;
    padding: 6px 0;

    &:hover {
        text-decoration: underline;
    }
}

.Responsive .FilterListItem a:hover .FilterName {
    text-decoration: none;
}

.SelectedFilter {
    display: block;
}

@media (min-width: 1022px) {
    .selectedFilter {
        margin-bottom: 8px;
    }
}

.selectedFilterToggle {
    background: var(--site-light-grey);
    position: relative;
    padding: 2px 4px;
    margin-right: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .global-icon-cross {
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 12px;

            path {
                stroke-width: 2px;
            }
        }
    }

    .selectedFilterLabel {
        color: var(--site-primary);
        font-size: var(--body-font-size);
        line-height: 20px;
    }
}

.filtersOverflow {
    background: var(--site-light-grey);
    position: relative;
    padding: 4px 8px;
    border-radius: var(--site-border-radius);
    margin-right: 4px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: var(--body-font-size);
        line-height: 20px;
    }
}

.brandSearchSort {
    padding: 0;

    @media (max-width: 1021px) {
        #spnSearchIcon {
            line-height: 44px;
        }
    }

    #divBrandSearch input {
        border-radius: var(--site-border-radius);
    }

    .SortQtyName {
        display: block;
    }
}

@media (min-width: 1022px) {
    .ACOL.FilterListItem__show,
    .category-ACOL .FilterListItem__hide {
        display: inline-block;
        width: 49%;
    }
}

.FilterShowMore {
    padding-left: 31px;
    font-size: var(--body-font-size);
    text-decoration: underline;
    margin-top: 4px;

    a {
        color: var(--site-primary);
    }
}

.topheadbox .SelectedFiltersWrapper {
    display: none !important;
}

.Browse .productFilterList .greyOut .FilterValue {
    background: none;
}

.Browse .productFilterList {
    .greyOut .SelectableFilter,
    .greyOut .SelectedFilter {
        --filter-border-colour: var(--site-dark-grey);
    }

    .SelectableFilter {
        --filter-border-width: 0;
        --filter-background-colour: var(--filter-colour, var(--site-white));
    }

    .SelectedFilter {
        --filter-border-width: 2px;
        --filter-background-colour: var(--filter-colour, var(--site-red));
    }

    @media (max-width: 1021px) {
        background-position: -376px -264px;
    }

    .SelectableFilter,
    .SelectedFilter {
        --filter-border-colour: var(--filter-colour, var(--site-primary));
        background: none;
        padding: 0;
        line-height: 1.1em;
        display: flex;
        align-items: center;

        &::before {
            background: var(--site-white);
            box-shadow: 0 0 0 1px var(--filter-border-colour);
            border-radius: var(--spacing-unit);
            width: 16px;
            min-width: 16px;
            max-width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: top;
            font-size: 11px;
            margin-left: 1px;
            border: var(--filter-border-width) solid var(--site-white);
        }

        &[data-item$="ACOL^Black"],
        &[data-item$="273_5209404^Black"] {
            --filter-colour: var(--site-black);
        }

        &[data-item$="ACOL^Blue"],
        &[data-item$="273_5209404^Blue"] {
            --filter-colour: #3f50e3;
        }

        &[data-item$="ACOL^Grey"],
        &[data-item$="273_5209404^Grey"] {
            --filter-colour: #999;
        }

        &[data-item$="ACOL^Pink"],
        &[data-item$="273_5209404^Pink"] {
            --filter-colour: #e398c5;
        }

        &[data-item$="ACOL^Brown"],
        &[data-item$="273_5209404^Brown"] {
            --filter-colour: #683f2e;
        }

        &[data-item$="ACOL^Red"],
        &[data-item$="273_5209404^Red"] {
            --filter-colour: #ed0000;
        }

        &[data-item$="ACOL^Green"],
        &[data-item$="273_5209404^Green"] {
            --filter-colour: #62904c;
        }

        &[data-item$="ACOL^Purple"],
        &[data-item$="273_5209404^Purple"] {
            --filter-colour: #693ee1;
        }

        &[data-item$="ACOL^Beige"],
        &[data-item$="273_5209404^Beige"] {
            --filter-colour: #f1e2d0;
        }

        &[data-item$="ACOL^Orange"],
        &[data-item$="273_5209404^Orange"] {
            --filter-colour: #ec8044;
        }

        &[data-item$="ACOL^Silver"],
        &[data-item$="273_5209404^Silver"] {
            --filter-colour: #c4c4c4;
        }

        &[data-item$="ACOL^Yellow"],
        &[data-item$="273_5209404^Yellow"] {
            --filter-colour: #f1e15d;
        }

        &[data-item$="ACOL^Gold"],
        &[data-item$="273_5209404^Gold"] {
            --filter-colour: #c9a854;
        }

        &[data-item$="ACOL^Metallics"],
        &[data-item$="273_5209404^Metallics"] {
            --filter-colour: linear-gradient(222.8deg, #565656 0%, rgba(255, 255, 255, 0.38) 50%, #565656 100%);

            &::before {
                box-shadow: 0 0 0 1px #565656;
            }

            &:hover::before {
                background-position: center;
            }
        }

        &[data-item$="ACOL^Cream"],
        &[data-item$="273_5209404^Cream"] {
            --filter-colour: #e7eaec;
        }
    }
    .SelectedFilter {
        &::before {
            background: var(--site-black);
        }
    }
}

#PriceFilterTextEntry {
    padding: 0;
    display: flex;
}

#PriceFilterTextEntryApply {
    margin-top: 0;
    margin-left: 10px;
    background-color: var(--site-button-primary-background);
    color: var(--site-button-primary-color);

    &:hover {
        background-color: var(--site-button-primary-background-hover);
        color: var(--site-button-primary-color-hover);
    }
}

#PriceFilterTextEntryError {
    position: absolute;
    margin-top: 40px;
    margin-bottom: 0;
}

#PriceFilterTextEntry input {
    border-radius: 4px;
}

.Responsive .mobDdClose .clsFilterIcon {
    font-family: initial;
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.Responsive .mobDdClose .clsFilterIcon::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background-size: 900px 600px;
    background-position: -541px -281px;
}

.Responsive .mobDdClose {
    width: 40px;
    line-height: 40px;
    height: 40px;
    padding: 0;
    border: 0;
    top: auto;
    right: 5px;
    bottom: 22px;
    position: absolute;
}

.Browse .productFilterTitleBox ~ .clearfilters.visible {
    display: block;
    font-size: 12px;
}

.productFilter .clearfilters.visible .productFilterClear {
    text-transform: none;
    float: none;
    padding: 12px 0;
    display: block;
    font-weight: var(--font-weight-bold);
    font-size: var(--body-font-size) !important;
    color: var(--site-primary);
}

.productFilter .productFilterList {
    padding: 12px 0 15px;
}

.productFilter .productFilterList #spnClearBrandFilter {
    position: absolute;
    width: 30px;
    top: 0;
    right: 0;
    background-size: 625px 625px;
    background-position: -372px -290px;
    overflow: hidden;
}

.Browse .sort-by-in-filters label,
.Browse .productFilterList .FilterName,
.FilterShowMore {
    font-size: var(--body-font-size);
    flex-grow: 1;
    margin-bottom: 0;
    line-height: 1.4em;
    font-weight: var(--font-weight-regular);
}

.showFilterButtonWrapper .showFilterButton:hover {
    color: var(--site-white) !important;
}

@media (min-width: 1022px) {
    #FiltersHeader {
        position: sticky;
        top: 0;
    }

    .productFilterList {
        border: none;
        padding: 0 4px 0 0;
        max-height: none;
    }

    .ACOL.FilterListItem__show,
    .category-ACOL .FilterListItem__hide {
        width: 100%;
    }

    .Responsive .productFilterList .FilterAnchor > span {
        display: flex;
        align-items: center;
    }

    .Browse .productFilterList .FilterName {
        width: auto;
    }

    .Browse .productFilterList .FilterValue font {
        vertical-align: initial !important;
    }

    .Browse .productFilterList .SelectableFilter::before,
    .Browse .productFilterList .SelectedFilter::before {
        width: 14px;
        min-width: 14px;
        max-width: 14px;
        height: 14px;
    }

    .Browse .productFilterList .SelectedFilter::before {
        background: var(--site-mid-grey);
    }

    .FilterShowMore {
        padding-left: 28px;
    }

    #FiltersAndProductsWrapper #FilterContainer ul.productFilters .productFilter .FilterAnchor {
        padding: 6px 5px 6px 0;
    }

    #FiltersAndProductsWrapper
        #FilterContainer
        ul.productFilters
        .productFilter
        .FilterListItem:first-child
        .FilterAnchor {
        padding: 0 5px 6px 0;
    }

    .productFilter .productFilterList .brandSearchSort {
        padding: 0 6px 0 0;
    }

    .Browse .productFilterList .SelectableFilter:hover .FilterName,
    .Browse .productFilterList .SelectedFilter:hover .FilterName {
        text-decoration: underline;
    }

    #PriceFilterTextEntryMin,
    #PriceFilterTextEntryMax {
        width: 33%;
    }
}

@media (min-width: 1440px) {
    .MobSortSelectionCheckbox label {
        margin: 0;
    }
}

@media (min-width: 1022px) {
    .Browse .productFilterList .FilterName,
    .Browse .productFilterList .FilterValue {
        line-height: 18px;
    }
}

.mobile-sort-and-filter-wrapper .MobSortSelector > .productFilterList .SelectableFilter.SelectedFilter::before,
.Browse .productFilterList .SelectedFilter::before {
    background: var(--site-black);
}

.watchshop-plp {
    ul.productFilters li.productFilter .selectedFilterToggle {
        padding: 2px 30px 2px 4px;
    }

    ul.productFilters li.productFilter .selectedFilterToggle::after,
    ul.productFilters li.productFilter .selectedFilterToggle::before {
        content: "";
        position: absolute;
        width: 11px;
        height: 1px;
        background-color: var(--site-black);
        top: 50%;
        transform: translateY(-50%);
    }

    ul.productFilters li.productFilter .selectedFilterToggle::before,
    ul.productFilters li.productFilter .selectedFilterToggle::after {
        transform: translateY(-50%) rotate(45deg);
        right: 10px;
    }
}
