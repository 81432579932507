#hotspotModal {
    .modal-dialog {
        max-width: 1030px;
        border-radius: var(--site-border-radius);
        background-color: var(--site-white);

        .modal-content {
            background-color: var(--site-white);
            border: none;
            outline: none;
            border-radius: var(--site-border-radius);
        }

        @media (max-width: 767px) {
            margin-top: 0 !important;
            height: 100%;
            border-radius: 0;

            .modal-content {
                height: 100%;
                max-height: initial !important;
            }
        }
    }

    .modal-header {
        position: absolute;
        height: 49px;
        padding: calc(2.5 * var(--spacing-unit)) calc(11 * var(--spacing-unit));

        .close {
            height: 15px;
            width: 15px;
            right: 15px;
            top: 15px;
        }
    }

    .modal-body {
        padding: 0;

        .row {
            margin: 0;
        }

        @media (min-width: 1022px) {
            padding: 0 0 calc(13 * var(--spacing-unit));
        }

        @media (max-width: 767px) {
            height: 100%;
            max-height: initial !important;
        }
    }

    .PinWrapImage {
        padding: calc(4 * var(--spacing-unit));
        max-width: 556px;
        width: 100%;

        @media (min-width: 768px) {
            max-width: 50%;
        }

        @media (min-width: 1022px) {
            max-width: 556px;
        }

        .swiper-pagination {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: calc(var(--spacing-unit) * 2);
            bottom: 0;

            .swiper-pagination-bullet {
                height: 3px;
                width: 34px;
                margin: 0;
                border-radius: 0;
                background: var(--site-mid-grey);
                opacity: 1;
            }

            .swiper-pagination-bullet:hover {
                background: var(--site-mid-grey);
            }

            .swiper-pagination-bullet-active {
                background: var(--site-black);
            }
        }
    }

    .PinWrapText {
        width: 100%;
        padding: calc(6.25 * var(--spacing-unit));

        .col-xs-12 {
            width: 100%;
            flex: 1 1 100%;
            padding: 0;
        }

        .hsProductName {
            flex-grow: 1;
            flex-basis: calc(100% - 90px);
            padding: 0;
            order: 1;

            h2 {
                margin: 0;
                padding: 0;
                font-size: var(--subtitle-font-size);
                line-height: 20px;
                font-weight: var(--font-weight-regular);

                span {
                    display: block;
                    font-weight: var(--font-weight-regular);
                    color: var(--site-primary);
                }
            }
        }

        .member-price {
            order: 2;
            font-size: var(--body-font-size);
            text-transform: capitalize;
            font-weight: var(--font-weight-bold);
            font-family: var(--default-font-family);
            padding: calc(var(--spacing-unit) * 4) 0 0;
            font-size: var(--subtitle-font-size);
            margin: 0;
        }

        .hsColourDesc {
            order: 3;
        }

        .hsColourSelection {
            order: 4;
        }

        .BuySizeText {
            order: 5;
        }

        .sizeGuideLink {
            order: 6;
        }

        .hsSizeSelection {
            order: 7;
        }

        #hsPriceWrapper {
            padding: calc(var(--spacing-unit) * 4) 0 0;
            flex-basis: 100%;
            order: 2;
            display: flex;
            flex-wrap: wrap;

            #hsPrice,
            #hsRefPrice {
                display: block;
                text-align: left;
                padding: 0;
                font-size: var(--subtitle-font-size);
                line-height: 20px;
                margin-right: var(--spacing-unit);
            }

            #hsPrice {
                color: var(--site-sale);
                font-size: var(--subtitle-font-size);
                font-weight: var(--font-weight-bold);
            }

            #hsPrice:only-child,
            #hsRefPrice {
                color: var(--site-primary);
                font-weight: var(--font-weight-regular);
            }
        }

        #hsAdditionalPriceLabel {
            display: inline-block;
            font-size: var(--body-font-size);
            padding: 0 var(--spacing-unit) 0 0;
            line-height: 1.3em;
            float: none;
            color: var(--site-black);
        }

        .hsColourDesc,
        .BuySizeText,
        .sizeGuideLink {
            margin: calc(6 * var(--spacing-unit)) 0 var(--spacing-unit);
            padding: 0 var(--spacing-unit) 0 0;
        }

        .hsColourDesc .ColourDesc,
        .hsColourDesc .ColourLabel,
        .hsSizeLabel {
            font-style: normal;
            font-size: var(--body-font-size);
            letter-spacing: normal;
            color: var(--site-primary);
        }

        .hsColourDesc .ColourDesc {
            font-weight: var(--font-weight-regular);
        }

        .hsSizeLabel,
        .hsColourDesc .ColourLabel {
            font-weight: var(--font-weight-bold);

            &::after {
                content: ":";
            }
        }

        .hsVariantButtons {
            display: flex;
            flex-wrap: wrap;
            padding-right: var(--spacing-unit);
            gap: var(--spacing-unit);

            li {
                margin: 0;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: var(--subtitle-font-size);
                    line-height: 1;
                    border-color: var(--site-mid-grey);
                    color: var(--site-primary);
                    border-radius: var(--site-border-radius);
                    overflow: hidden;
                    padding: 0;
                }

                a:hover {
                    border-color: var(--site-primary);
                    background-color: var(--site-light-grey);
                }

                &.hsVariantHighlight a {
                    border-color: var(--site-primary) !important;
                    background-color: var(--site-white);
                }

                &.greyOut a {
                    background: var(--site-light-grey);
                    color: var(--site-mid-grey);
                    border: 1px solid var(--site-mid-grey);
                }
            }
        }

        .hsColourSelection .hsVariantButtons,
        .hsSizeSelection .hsVariantButtons {
            padding: 0;
            margin: 0 -5px;
        }

        .BuySizeText,
        .sizeGuideLink {
            flex-basis: 50%;
        }

        .sizeGuide-text {
            text-decoration: underline;
        }

        #ulHsSizes li {
            flex-basis: calc(25% - 3px);
        }

        .hsVariantHighlight {
            &::before {
                content: none;
            }
        }

        #hsAddToBagContainer,
        #hsAddToWishListContainer {
            margin-top: calc(var(--spacing-unit) * 6);

            a {
                background-color: var(--site-button-secondary-background) !important;
                color: var(--site-button-primary-color) !important;
                font-family: var(--default-font-family);
                font-weight: var(--font-weight-bold);
                font-size: var(--subtitle-font-size);
                padding: calc(var(--spacing-unit) * 5) var(--spacing-unit);
                text-transform: capitalize;
                border-radius: var(--site-button-border-radius);

                &:hover {
                    background-color: var(--site-button-secondary-background-hover) !important;
                    color: var(--site-button-secondary-color-hover) !important;
                }
            }
        }

        .hsbottom {
            width: 100%;
            order: 8;
            position: static;
            padding: 0 0 calc(var(--spacing-unit) * 2.5);
        }

        #hsViewProduct {
            text-align: center;
            display: flex;
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 1;
            height: 52px;
            align-content: center;
            align-items: center;
            justify-content: center;
            justify-items: center;
            width: 100%;
            background-color: var(--site-button-secondary-background);
            border-radius: var(--site-button-border-radius);
            transition: background-color 0.2s ease-in-out;

            a {
                display: block;
                width: 100%;
                height: 100%;
                text-transform: initial;
                text-decoration: none;
                color: var(--site-button-secondary-color);
                font-weight: var(--font-weight-bold);
                font-size: var(--body-font-size);
                padding: calc(var(--spacing-unit) * 4) 0;
                border-radius: 0 0 var(--site-button-border-radius);
                transition: all 0.3s ease-in;
                background: var(--site-black);

                &:hover {
                    background-color: var(--site-secondary);
                    color: var(--site-button-secondary-color-hover);
                }
            }

            &:hover {
                background: var(--site-button-secondary-background-hover);
                a {
                    color: var(--site-button-secondary-color-hover);
                }
            }

            @media (max-width: 1021px) {
                position: static;
                display: block;
            }
        }

        @media (min-width: 768px) {
            padding: calc(var(--spacing-unit) * 12) calc(var(--spacing-unit) * 12) calc(var(--spacing-unit) * 12)
                calc(var(--spacing-unit) * 11);
            display: flex;
            flex-wrap: wrap;
            width: 50%;
            position: static;
        }

        @media (max-width: 1021px) {
            width: 100%;
            padding: calc(var(--spacing-unit) * 6);
        }

        @media (min-width: 1022px) {
            width: calc(100% - 556px);
        }
    }
}
