#divMultiplePurchases {
    display: none;
}

.qtyBorder {
    border: 1px solid var(--site-mid-grey);
    background-color: var(--site-white);
    display: flex;
    max-width: 125px;
    margin-right: var(--spacing-unit);
    border-radius: var(--site-button-border-radius);
    align-items: center;
}

.watchshop-pdp .s-basket-minus-button,
.watchshop-pdp .s-basket-plus-button {
    transition: background-color linear 0.3s;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--site-white);
    border-radius: var(--site-button-border-radius) 0 0 var(--site-button-border-radius);
    margin: 0;
    padding: 0 calc(var(--spacing-unit) * 3);
    flex: 1;
}

.watchshop-pdp .s-basket-plus-button {
    border-radius: 0 var(--site-button-border-radius) var(--site-button-border-radius) 0;
}

.watchshop-pdp .s-basket-minus-button > span,
.watchshop-pdp .s-basket-plus-button > span {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 10px;
    height: 10px;
}

.watchshop-pdp .s-basket-minus-button > span svg {
    height: 10px;
}

.watchshop-pdp .s-basket-minus-button svg path {
    stroke: var(--site-primary);
}

.watchshop-pdp .s-basket-minus-button:hover,
.watchshop-pdp .s-basket-plus-button:hover {
    background-color: var(--site-white);
    color: var(--site-primary);
    border: 1px solid var(--site-primary);
}

.watchshop-pdp .s-basket-minus-button:focus,
.watchshop-pdp .s-basket-plus-button:focus {
    background-color: var(--site-secondary-lighter);
    color: var(--site-primary);
    border: 1px solid var(--site-primary);
}

.watchshop-pdp .s-basket-minus-button:hover svg path,
.watchshop-pdp .s-basket-plus-button:hover svg path,
.watchshop-pdp .s-basket-minus-button:focus svg path,
.watchshop-pdp .s-basket-plus-button:focus svg path {
    stroke: var(--site-primary);
}

.s-basket-quantity-text-box,
.s-basket-quantity-text-box input {
    height: 100%;
}

.s-basket-quantity-text-box input {
    border: none;
    padding: 0;
    margin: 0;
    max-width: 45px;
    letter-spacing: 0.03em;
}
