/*checkout*/
#CheckoutSpaRoot {
    header {
        background-color: var(--site-white);
        border-bottom: 1px solid var(--site-light-grey);

        .headerImg a img {
            max-width: 142px;

            @media (min-width: 1025px) {
                max-height: 36px;
                max-width: 240px;
            }
        }

        .securePad {
            position: relative;
            background: none;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='15' viewBox='0 0 12 15' fill='none'%3E%3Cpath d='M10.2857 6.625V4.875C10.2857 2.425 8.4 0.5 6 0.5C3.6 0.5 1.71429 2.425 1.71429 4.875V6.625C0.771429 6.625 0 7.4125 0 8.375V12.75C0 13.7125 0.771429 14.5 1.71429 14.5H10.2857C11.2286 14.5 12 13.7125 12 12.75V8.375C12 7.4125 11.2286 6.625 10.2857 6.625ZM8.57143 6.625H3.42857V4.875C3.42857 3.3875 4.54286 2.25 6 2.25C7.45714 2.25 8.57143 3.3875 8.57143 4.875V6.625Z' fill='%2300003C'/%3E%3C/svg%3E");
        }

        .CheckoutHeader .headerSecureCheckout,
        .CheckoutHeader .headerRightSecureCheckout {
            color: var(--site-primary);
        }

        .headerSummaryContainer {
            background-color: var(--site-secondary);
        }

        .CheckoutHeader .headerSummary {
            .summaryPrice,
            .summaryText {
                color: var(--site-white);
            }
        }

        .checkChevron span,
        .basketSummaryActive .checkChevron span {
            background: none;
            transform: none;
        }

        .checkChevron span::before {
            content: url('data:image/svg+xml,%0A%3Csvg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath fill-rule="evenodd" clip-rule="evenodd" d="M12.3265 1.22666L6.34415 7.20905L0.361761 1.22666L1.58842 -4.69378e-07L6.34415 4.75573L11.0999 7.73576e-07L12.3265 1.22666Z" fill="%23FFFFFF"/%3E%3C/svg%3E%0A');
            display: flex;
            justify-content: center;
            scale: 1.25;
        }

        .basketSummaryActive .checkChevron span::before {
            transform: rotate3d(1, 0, 0, 180deg);
        }
    }

    footer {
        background-color: var(--site-lightest-grey);

        #footerStepMsg {
            color: var(--site-primary);
            font-weight: var(--font-weight-bold);
        }

        .innerFooter {
            color: var(--site-dark-grey);
        }
    }

    .sectionGroup {
        h1 {
            font-family: var(--default-font-family);
            font-weight: var(--font-weight-bold);
            text-transform: uppercase;
            color: var(--site-dark-grey);

            &::before {
                background-color: var(--site-dark-grey);
            }
        }
    }

    .activeSection {
        .sectionGroup {
            h1 {
                color: var(--site-primary);

                &::before {
                    background-color: var(--site-secondary);
                }
            }
        }
    }

    button {
        font-family: var(--default-font-family);
        background-color: var(--site-button-secondary-background) !important;
        font-weight: var(--font-weight-bold);
        color: var(--site-white) !important;
        cursor: pointer;
        text-transform: none;
        letter-spacing: 0;

        &:hover {
            background-color: var(--site-button-secondary-background-hover) !important;
        }

        &::before {
            opacity: 0;
        }
    }

    .summaryWrap {
        .summaryheader {
            @media (min-width: 1022px) {
                padding: 30px;
                border: 1px solid var(--site-mid-grey);
                background-color: var(--site-white);
            }

            .summaryTitleText {
                font-family: var(--default-font-family);
                font-style: normal;
                text-transform: none;
                font-weight: var(--font-weight-bold);
                color: var(--site-primary);
            }
        }

        .summaryList {
            @media (min-width: 1022px) {
                border-left: 1px solid var(--site-mid-grey);
                border-right: 1px solid var(--site-mid-grey);
            }

            .summaryListBrand,
            .summaryListTitle,
            .summaryListInfo,
            .summaryTotal div {
                font-family: var(--default-font-family);
            }

            .summaryListInfoPriSell {
                font-weight: var(--font-weight-regular);
            }

            .member-line-label-STUD {
                text-transform: capitalize;
                font-weight: var(--font-weight-bold);
            }
        }

        .summaryTotal {
            border-left: 1px solid var(--site-mid-grey);
            border-right: 1px solid var(--site-mid-grey);
            border-bottom: 1px solid var(--site-mid-grey);

            .member-total-STUD .summaryTotalTitle {
                text-transform: capitalize;
                font-weight: var(--font-weight-bold);
            }
        }
    }

    .delcountryEdit p a,
    .progressTitleTop,
    .deliveryPriceActual,
    .selectionOptions .estimatedDateGroup .estimatedDateValue time,
    .noRadio .paymentHeader h3,
    .alertGroup .alertInner .alertText,
    .summaryListProductCol > span,
    .summaryListProductSize > span,
    .summaryListProductQty > span,
    .summaryListProductID > span,
    .summaryListText .summaryListBrand,
    .summaryListText .summaryListTitle {
        color: var(--site-primary);
    }

    .progressTitleSub,
    .selectionOptions .deliveryDesc {
        color: var(--site-dark-grey);
    }

    .summaryListInfo {
        font-size: var(--body-font-size-small);
    }

    .summaryListText {
        width: calc(100% - 150px);
    }

    .selectionOptions .selectionDescription .SpendVoucher {
        padding: 5px 15px 0 0;
    }

    .selectionOptions .deliveryDesc + .estimatedDateGroup {
        list-style: none;
    }

    .estimatedDateGroup .estimatedDateValue {
        font-weight: var(--font-weight-bold);
    }

    .ace & .paymentOptionsDisplayedBeforeGiftAndPromo .studiopayPayment {
        order: -1;
    }

    .summaryandComplete {
        .member-saving-STUD {
            span {
                font-size: 0.9em;
                text-transform: capitalize;
            }
        }

        .member-total-STUD {
            border-top: 1px var(--product-line-item-action-border-color) solid;
            padding-top: 10px;
            margin: 10px 0 0;

            .summaryTotalTitle,
            .summaryTotalAmount {
                text-transform: capitalize;
                font-size: 0.9em;
            }
        }
    }
}
