#divBag #divBagTotalLink {
    padding: 0;
    font-size: var(--body-font-size);

    #aBagLink:hover {
        background-color: unset;
    }
}

#divBag #bagName {
    display: none;
}

.active #divBagTotalLink a#aBagLink {
    &:hover + .lillBasket #divBagItems {
        display: block;
    }

    #bagTotalContainer,
    .bagIcon {
        display: none;
    }
}

.Responsive #divBag {
    width: 50px;
    padding: 0;
    font-size: var(--body-font-size);

    @media (min-width: 1022px) {
        width: 60px;
    }
}

.lillBasket #aBagLink span#bagQuantity {
    background: none;
    background-color: var(--site-secondary);
    color: var(--site-white);
    min-height: 22px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    min-width: 22px;
    height: auto;
    position: absolute;
    top: -8px;
    left: 54%;
    width: auto;
    text-align: center;
    font-size: calc(var(--body-font-size) - 2px);
    line-height: 1;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &.empty,
    &.hide-count {
        display: none;
    }
}

.basketLink .langlogin .login {
    display: block;
    padding-top: calc(var(--spacing-unit) * 3);
    padding-left: calc(var(--spacing-unit) * 8.75);
    height: 100%;
    font-size: var(--body-font-size);
}

.lillBasket .navPage {
    display: block !important;
    min-height: 0;
}

.lillBasket .BaskColr::before {
    content: "Colour:";
    display: inline-block;
    margin-right: calc(var(--spacing-unit) * 1.25);
}

.lillBasket .BaskSize::before {
    content: "Size:";
    display: inline-block;
    margin-right: calc(var(--spacing-unit) * 1.25);
}

.lillBasket #aViewBag {
    width: 100%;
    line-height: 1.55em;
    border-color: var(--site-black);
    outline: 1px solid var(--site-white);
    outline-offset: -2px;
    font-size: var(--subtitle-font-size);
    margin-bottom: calc(var(--spacing-unit) * 2.5);
    padding: 0;
    color: var(--site-black) !important;
    background-color: var(--site-white) !important;
    text-transform: capitalize;

    span {
        display: block;
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);
        border-radius: var(--site-button-border-radius);
        padding: calc(var(--spacing-unit) * 3.75);
        color: var(--site-black);
        height: 100%;
    }

    &:hover {
        background-color: var(--site-white) !important;
        border-color: var(--site-secondary);
        outline-color: var(--site-secondary);
    }

    &:hover,
    &:focus {
        color: var(--site-button-secondary-color-hover) !important;
        border-radius: var(--site-border-radius);

        span {
            color: var(--site-black) !important;
        }
    }
}

.lillBasket #spanCheckout {
    width: 100%;
}

@media (min-width: 768px) {
    .lillBasket .summaryWrapCTA {
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-height: none;
    }

    .lillBasket #aViewBag,
    .lillBasket #spanCheckout {
        margin-bottom: 0;
        height: 52px;
    }

    #divBagTotalLink a#aBagLink:hover .global-bag-icon__inner {
        fill: var(--site-black);
    }
}

.lillBasket #ulBag {
    font-size: var(--body-font-size);

    a {
        width: auto;
        float: none;
        color: var(--site-primary);
        font-weight: var(--font-weight-regular);
        display: flex;
        padding: calc(var(--spacing-unit) * 2.5) 0;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }
    }

    li {
        border-bottom: solid 1px var(--site-light-grey);
        padding: calc(var(--spacing-unit) * 5) 0 calc(var(--spacing-unit) * 2.5);
        position: relative;
        margin: 0 calc(var(--spacing-unit) * 6);

        &:last-of-type {
            border-bottom: 0;
        }

        span {
            line-height: 1.2;
            height: auto;
        }

        .maxText {
            float: right;
            padding-right: 0;
            clear: right;
            max-width: 35%;
        }

        .lineProblems {
            clear: left;
            max-width: 65%;
        }
    }
}

.lillBasket .bagContentItemWrap {
    font-size: var(--body-font-size);
    position: relative;
    display: flex;
    flex-flow: row wrap;
    overflow-y: auto;
    padding-bottom: 0;
}

.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.lillBasket .PreviousDisable,
.lillBasket .NextDisable,
.wishlist-summary .items-nav {
    font-size: 11px;
    background-size: 1000px 1000px;
    opacity: 0.3;
    background-position: -228px -560px;
}

.lillBasket .NextEnable,
.lillBasket .NextDisable,
.wishlist-summary .items-nav[data-action="next"] {
    background-position: -228px -521px;
}

.lillBasket .PreviousEnable,
.lillBasket .NextEnable,
.wishlist-summary .items-nav:hover,
.wishlist-summary .items-nav:focus,
.wishlist-summary .items-nav.PreviousEnable,
.wishlist-summary .items-nav.NextEnable {
    opacity: 1;
}

.lillBasket a.removeClass::before,
.s-basket-remove-button a .deleteItemIcon {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    font-size: 11px;
    background-size: 600px 600px;
    background-position: -444px -287px;
    margin-right: calc(var(--spacing-unit) * 2);
    zoom: 1.1;
}

.lillBasket a.bagMoveToWishlistClass::before {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    font-size: 11px;
    background-size: 800px 800px;
    background-position: -242px -425px;
    content: "";
    margin-right: calc(var(--spacing-unit) * 2);
    zoom: 1.1;
}

.s-basket-remove-button a .deleteItemIcon {
    text-indent: -9999px;
}

.lillBasket a.removeClass::before {
    content: "";
}

.s-basket-remove-button a:hover .deleteItemIcon,
.lillBasket a.removeClass::before:hover,
.lillBasket a.bagMoveToWishlistClass::before:hover {
    background-position: -394px -307px;
}

.updateIcon,
.BasketNew .BasketQuantBut::before,
.BasketNew .BasketQuantBut.s-basket-plus-button::before,
.Responsive .BasketNew #BasketDiv .NewUpdateQuant:hover .updateIcon {
    background-size: 770px 770px;
    background-position: -204px -318px;
}

.BasketNew .BasketQuantBut {
    &::before {
        background-position: -155px -351px;
    }

    &.s-basket-plus-button::before {
        background-position: -199px -351px;
    }
}

.lillBasket #divBagItems,
#divAjaxLoaderImage,
#divEmptyErrorMessage {
    box-shadow: none;
    border: 1px solid var(--site-mid-grey);
    z-index: 10;
    bottom: 0;
    top: auto;
    border-radius: var(--site-border-radius) var(--site-border-radius) 0 0;
}

.lillBasket .innerdivBagItems {
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.lillBasket #divButtons {
    padding: calc(var(--spacing-unit) * 3) calc(var(--spacing-unit) * 6) calc(var(--spacing-unit) * 4);
}

.lillBasket #aCheckout {
    font-size: var(--body-font-size);
    font-family: var(--default-font-family);
    font-weight: var(--font-weight-bold);
    padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 1.25);
    border-radius: var(--site-button-border-radius);
    background-color: var(--site-button-secondary-background);
    text-transform: capitalize;
    line-height: 1.5em;

    &:hover {
        background-color: var(--site-button-secondary-background-hover);

        span {
            color: var(--site-button-primary-color-hover);
        }
    }

    span {
        color: var(--site-button-primary-color);

        &::after {
            content: "";
            display: inline-block;
            height: 16px;
            width: 16px;
            background-repeat: no-repeat;
            float: none;
            margin-left: var(--spacing-unit);
            font-size: 11px;
            vertical-align: top;
            background-size: 600px 600px;
            background-position: -419px -254px;
        }
    }
}

.lillBasket #divBagItemsChild {
    padding: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.lillBasket .ColrandSize {
    display: flex;
    width: 65%;
    flex-basis: 65%;
    flex-wrap: wrap;
    order: 3;
    padding-right: 0;
    padding-top: 0;
    line-height: 1.4;

    .BaskColr,
    .BaskSize {
        display: flex;
        flex-basis: 100%;
        flex-grow: 1;
        width: 100%;
    }

    span {
        font-weight: var(--font-weight-regular);
        color: var(--site-primary);
        padding-top: var(--spacing-unit);
    }
}

.lillBasket .BaskQuant {
    font-weight: var(--font-weight-regular);
    padding: var(--spacing-unit) 0 0;
    display: flex;
    order: 4;
    flex-basis: 35%;
    width: 35%;
    justify-content: flex-end;
    text-align: right;
}

#divBagItems .fulfilledBy {
    width: 70%;
    clear: left;
}

.lillBasket .BaskName {
    height: auto;
    width: 65%;
    flex-basis: 65%;
    order: 1;
    color: var(--site-primary);
    font-weight: var(--font-weight-regular);
    -webkit-line-clamp: 1;
    /*stylelint-disable*/
    display: -webkit-box;
    /*stylelint-enable*/
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lillBasket .liPrdLnk:hover,
.lillBasket .liPrdLnk:focus {
    .BaskName {
        text-decoration: underline;
        color: var(--site-primary);
    }
}

.lillBasket .PriceandCross {
    width: auto;
    float: none;
    display: flex;
    flex-basis: 100%;
    order: 6;

    .removeClass {
        justify-content: center;
        align-items: center;
        justify-items: center;
        display: flex;
    }
}

.lillBasket .bagMoveToWishlistContainer {
    width: auto;
    float: none;
    display: flex;
    flex-basis: 100%;
    order: 5;
    margin-top: calc(var(--spacing-unit) * 2.5);
}

.lillBasket #aViewBag,
.lillBasket #divBagTotalDiscount,
.lillBasket #spanCheckout {
    font-size: var(--body-font-size);
}

.lillBasket #divBagTotalDiscount {
    text-align: left;
}

.lillBasket #divButtons .SubBask {
    display: flex;
    max-height: 29px;
    margin-bottom: 0;

    > div {
        width: 50%;
        flex: 1 1 auto;
    }
}

.lillBasket .summaryWrapCTA {
    max-height: 114px;
}

.lillBasket .PriceandCross,
.lillBasket .bagMoveToWishlistContainer,
.lillBasket .BaskName,
.lillBasket .BaskQuant {
    color: var(--site-primary);
}

.lillBasket #lblBagSubTotal,
.lillBasket #spanBagSubTotalValue {
    font-size: var(--body-font-size);
    font-weight: inherit;
}

.lillBasket .global-icon-tick {
    margin-left: calc(var(--spacing-unit) * 2.5);
}

.lillBasket .basketTotalItemsHeader {
    padding-left: calc(var(--spacing-unit) * 2.5);
}

.lillBasket .BaskPrice {
    display: flex;
    flex-basis: 35%;
    justify-content: flex-end;
    order: 2;
    color: var(--site-primary);
    font-weight: var(--font-weight-regular);
}

#divulBagParent {
    flex: 1 1 auto;
    overflow: auto !important;
    scrollbar-width: thin;
    max-height: 50vh;
    scrollbar-color: var(--site-primary) var(--site-light-grey);
}

.Responsive #divulBagParent {
    overflow: auto !important;
}

#divBagItemsChild .bagHeader {
    flex: 0 0 auto;

    p {
        display: flex;
        text-transform: uppercase;
        font-size: calc(var(--subtitle-font-size) + 2px);
        font-weight: var(--font-weight-bold);
        border-bottom: 1px solid var(--site-light-grey);
        text-align: left;
        padding: calc(var(--spacing-unit) * 7.5) calc(var(--spacing-unit) * 6);
        margin: 0;
        line-height: 25px;
    }

    #clsBasketMob {
        font-size: var(--body-font-size);
        width: 42px;
        right: 10px;
        font-family: initial;
        top: 18px;
        height: 42px;
        line-height: 30px;

        .global-icon-cross {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &.glyphicon-remove::before {
            content: none !important;
        }
    }
}

@media (max-width: 767px) {
    .lillBasket #divBagItems,
    #divAjaxLoaderImage,
    #divEmptyErrorMessage {
        right: 0;
        position: fixed !important;
        bottom: 0;
        top: auto;
    }

    .lillBasket #divBagItemsChild {
        max-height: calc(100vh - 85px) !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 768px) {
    .lillBasket #divBagItems,
    #divAjaxLoaderImage,
    #divEmptyErrorMessage {
        border-radius: 0;
        bottom: auto;
        top: 71px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    }

    .lillBasket #divBagItems {
        background-color: transparent;
        border: 1px solid var(--site-mid-grey);
        overflow: hidden;

        &::before {
            content: "";
            display: block;
            width: 24px;
            height: 12px;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid var(--site-white);
            position: absolute;
            right: 17px;
            top: 0;
        }
    }

    .lillBasket .innerdivBagItems {
        background-color: var(--site-white);
        max-height: calc(100vh - 55px) !important;
        overflow: hidden;
    }

    .lillBasket #divBagItemsChild {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 1022px) {
    #divBag .HeaderBagEmptyMessage,
    #divBag .HeaderCheckoutLink {
        font-size: var(--body-font-size);
    }
}

.Basket .elevated-cart {
    .basket-heading,
    .basket-summary-header {
        font-weight: var(--font-weight-bold);
    }

    .PromoCodeBut {
        background-color: var(--site-black) !important;
        color: var(--cart-button-font-color) !important;
        text-transform: capitalize;
        border-radius: var(--site-border-radius);
        border: none;

        &:hover,
        &:focus {
            background-color: var(--site-button-secondary-background-hover) !important;
            color: var(--site-button-secondary-color-hover) !important;
            border: none;
        }
    }

    .basket-summary-continue-button-container .ContinueOn {
        border: none;
        text-transform: capitalize;
        border-radius: var(--site-border-radius);
        background-color: var(--site-button-secondary-background-hover) !important;
    }

    .basket-summary-continue-button-container .ContinueOn:hover {
        border: none;
        background-color: var(--site-secondary) !important;
        color: var(--site-white) !important;
    }

    @media (min-width: 1022px) {
        .basket-summary,
        .basket-header {
            top: calc(var(--cart-sticky-element-default-top-value) + 16px);
        }

        .basket-header::before {
            height: 27px;
            top: -27px;
        }

        .basket-details-container {
            margin-top: calc(var(--cart-details-margin-top-value));
        }
    }
}
