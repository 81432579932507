.WishListProductQuantityControls .TextQuantity span,
#WishListDisplay .saleprice {
    font-weight: var(--font-weight-regular);
}

#divWishList .wishlist-summary {
    top: 71px;
    z-index: 5900;

    &::before {
        display: none;
    }

    .wishlist-summary-inner {
        margin-top: 0;
        max-height: calc(100vh - 55px);
        border: 1px solid var(--site-mid-grey);
    }

    .ul-container {
        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: var(--site-light-grey);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--site-primary);
            border-radius: calc(var(--site-border-radius) + 1px);
            border: none;
        }
    }

    .wishlist-summary-header {
        padding: calc(var(--spacing-unit) * 7.5) calc(var(--spacing-unit) * 6);

        .wishlist-summary-tick .tick-tick {
            fill: var(--site-white);
        }
    }

    .wishlist-summary-footer .ctaBtn {
        background-color: var(--site-button-secondary-background);
        color: var(--site-button-secondary-color);
        font-weight: var(--font-weight-bold);
        font-family: var(--default-font-family);
        border-radius: var(--site-button-border-radius);
        border: none;
        text-transform: var(--wishlist-button-text-transform);
        letter-spacing: 0;

        &:hover {
            background-color: var(--site-button-secondary-background-hover);
            color: var(--site-button-secondary-color-hover);
        }

        &.signIn {
            font-family: var(--font-family-bold);
            font-style: var(--wishlist-button-font-style);
            border-radius: var(--site-button-border-radius);
            transition: all 250ms linear 0s;
            color: var(--site-black);
            background-color: var(--site-white);
            border-color: var(--site-black);
            outline: 1px solid var(--site-white);
            outline-offset: -2px;

            &:focus {
                color: var(--site-black);
                background-color: var(--site-white);
                border-color: var(--site-secondary);
                outline-color: var(--site-secondary);
            }

            &:hover {
                color: var(--site-black);
                background-color: var(--site-white);
                border-color: var(--site-secondary);
                outline-color: var(--site-secondary);
            }
        }
    }

    .wishlist-summary-header .wishlist-summary-count,
    .wishlist-summary-header h3 {
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);
        font-size: 18px;
        line-height: 25px;
    }
}

.wishlist-page {
    max-width: var(--container-max-width);
    margin: 0 auto;

    .wishlist-page__header {
        z-index: 1;
        top: 0;
        transition: top 0.1s ease-in-out;

        &::before {
            display: none;
        }
    }

    .wishlist-page__header-share-button {
        &:hover {
            color: var(--site-black) !important;
        }
    }

    h1 span {
        font-weight: var(--font-weight-bold);
        font-family: var(--default-font-family);
    }

    .wishlist-page__primary-button {
        background-color: var(--site-button-primary-background);
        font-family: var(--default-font-family);
        color: var(--site-button-primary-color) !important;
        font-style: normal;
        font-weight: var(--font-weight-bold);
        text-transform: var(--wishlist-button-text-transform);
        letter-spacing: 0;
        transition:
            color 0.2s ease-in-out,
            background-color 0.2s ease-in-out;

        &:hover,
        &:focus {
            background-color: var(--site-button-primary-background-hover);
            color: var(--site-button-primary-color-hover) !important;
        }
    }
}

.headerFix {
    .wishlist-page__header {
        top: 0;
        transition: top 0.25s ease-in-out 0.1s;
    }

    &.menu-search-shown .wishlist-page__header {
        top: 120px;
    }
}

.wishlist-share {
    border: 1px solid var(--site-mid-grey);
    border-radius: var(--site-border-radius);

    .wishlist-share__header {
        border-radius: var(--site-border-radius) var(--site-border-radius) 0 0;

        h1 {
            display: flex;
            align-items: center;

            span {
                font-family: var(--default-font-family);
                font-weight: var(--font-weight-bold);
            }
        }
    }

    .wishlist-share__body {
        background: var(--site-white);
        padding: calc(var(--spacing-unit) * 5) calc(var(--spacing-unit) * 6.25) calc(var(--spacing-unit) * 6.25);
    }

    .wishlist-share__close {
        top: 18px;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;

        svg {
            width: 16px;
        }
    }

    .wishlist-share__send-copy label {
        font-weight: var(--font-weight-regular);
        font-family: var(--default-font-family);
    }

    #share-wishlist-button {
        background-color: var(--site-button-primary-background);
        color: var(--site-button-primary-color) !important;
        font-family: var(--default-font-family);
        font-weight: var(--font-weight-bold);
        font-style: normal;
        text-transform: var(--wishlist-button-text-transform);

        &:hover {
            background-color: var(--site-button-primary-background-hover);
            color: var(--site-button-primary-color-hover) !important;
        }
    }

    .wishlist-share__item {
        border: none;
        padding: calc(var(--spacing-unit) * 2.5) 0;
    }

    .wishlist-share__input-email {
        border: 1px solid var(--site-dark-grey);
        color: var(--site-dark-grey);
        border-radius: 4px;
        padding: calc(var(--spacing-unit) * 3.75) calc(var(--spacing-unit) * 2.5);
        margin-bottom: calc(var(--spacing-unit) * 5);
    }

    .wishlist-share__send-copy {
        margin-bottom: calc(var(--spacing-unit) * 7);
    }

    .wishlist-share__message.wishlist-share__info {
        display: block;
        font-family: var(--default-font-family);
        padding-top: calc(var(--spacing-unit) * 5);
    }
}
