.infoaccordions .panel-heading h4 {
    font-family: var(--default-font-family);
    font-weight: var(--font-weight-bold);
    padding: 0;
    font-size: var(--body-font-size) !important;
    position: relative;
    color: var(--site-primary);
}

.infoaccordions .panel-heading h4::after,
.infoaccordions .panel-heading h4::before {
    content: "";
    display: block;
    height: 2px;
    width: 12px;
    border-radius: calc(var(--spacing-unit) / 2);
    background-color: var(--site-black);
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.25s;
}

.infoaccordions .panel-heading a h4::after {
    transform: rotate(0deg);
}

.infoaccordions .panel-heading a.collapsed h4::after {
    transform: rotate(-90deg);
}

.infoaccordions {
    #DisplayAttributes li span,
    #deliveryreturns #deliveryDetails li span,
    p,
    .SpendVoucher {
        font-size: var(--body-font-size);
        color: var(--site-primary);
        line-height: calc(var(--spacing-unit) * 5.5);
    }

    .productDescriptionInfoText {
        font-size: var(--body-font-size);
    }

    #deliveryDetails {
        .DeliveryMethod {
            margin-bottom: calc(var(--spacing-unit) * 5);
            justify-content: space-between;

            span.TitleDel {
                line-height: 15px;
                padding-bottom: var(--spacing-unit);
            }
        }

        .DeliveryMethodDescription {
            font-size: var(--body-font-size);
        }
    }

    #deliveryreturns {
        .returnsMethod {
            font-size: var(--body-font-size);

            h4 {
                font-size: var(--body-font-size);
                font-weight: var(--font-weight-bold);
                line-height: 15px;
                padding-bottom: var(--spacing-unit);
            }

            a {
                color: var(--site-black);
                text-decoration: underline;
            }
        }
    }
}

.infoaccordions p.productCode {
    color: var(--site-dark-grey);
}

.delivery_nextDate {
    display: none;
}

#MoreFromLinks {
    border: none;
    padding: 0 5px;
    margin: 8px 0 0;

    ul {
        margin-left: 10px;
    }

    .MoreFromLinksHeader {
        background: none;
    }

    #MoreFromLinksHeader {
        font-weight: var(--font-weight-bold) !important;
        font-size: var(--body-font-size) !important;
    }

    li.MoreFromLinksRow a {
        font-size: var(--body-font-size);
        font-weight: var(--font-weight-regular) !important;
    }
}
