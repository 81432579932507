.watchshop-pdp .dropShipSupplierInfoSummary {
    padding: 0;
    border: none;
    margin: 0 0 calc(var(--spacing-unit) * 5);
    text-align: left;

    .dropshipTitle,
    .dropshipSupplierName {
        color: var(--site-primary);
        font-size: var(--body-font-size);
    }
}

#DropShipSupplierModal {
    .modal-dialog {
        top: 50%;
        translate: 0 -50%;

        .modal-header {
            padding: calc(var(--spacing-unit) * 4) calc(var(--spacing-unit) * 10);
            height: auto;
            background-color: var(--site-white);
            border-bottom: 1px solid var(--site-mid-grey);

            .close {
                top: 11px;
            }
        }

        .modal-body {
            padding: 0;
            height: calc(100% - 61px);
        }
    }

    #dropshipAccordion .panel + .panel {
        margin: 0;
    }

    .DropModalContain {
        .panel-heading {
            background-color: var(--site-white);
            border-bottom: 1px solid var(--site-mid-grey);
        }

        .panel-title a {
            color: var(--site-primary);
            font-size: var(--body-font-size);
            font-weight: var(--font-weight-bold);
            position: relative;
        }

        .panel-collapse .panel-body {
            border-bottom: 1px solid var(--site-mid-grey);
            padding: calc(var(--spacing-unit) * 4);

            p:last-child {
                margin: 0;
            }
        }

        .panel-group .panel:last-child .panel-collapse .panel-body {
            border-bottom: none;
        }
    }

    #dropshipAccordion .panel-title a::after,
    #dropshipAccordion .panel-title a::before {
        content: "";
        display: block;
        height: 2px;
        width: 12px;
        border-radius: calc(var(--spacing-unit) / 2);
        background-color: var(--site-black);
        position: absolute;
        right: calc(var(--spacing-unit) * 4);
        top: 50%;
        transition: transform 0.25s;
    }

    #dropshipAccordion .panel-title a::after {
        transform: rotate(0deg);
    }

    #dropshipAccordion .panel-title a.collapsed::after {
        transform: rotate(-90deg);
    }
}
